import React from "react";
import { TypeAnimation } from "react-type-animation";
import styles from "./home-intro-text.module.scss";
import { HOME_TYPEWRITER_SEQUENCE } from "../../helpers/const";

const HomeIntroText = () => {
  return (
    <>
      <h1>Hei! Vi er Yne</h1>
      <h2 className={styles.type_animation_wrapper}>
        Konsulent
        <wbr />
        selskapet som er eksperter på
        <wbr />
        <TypeAnimation
          sequence={HOME_TYPEWRITER_SEQUENCE}
          wrapper="span"
          speed={45}
          repeat={Infinity}
        />
      </h2>
      <p className={styles.intro_paragraph}>
        Vi bygger fremtidens konsulenthus med spisskompetanse innenfor
        systemutvikling, for å skape teknologien du vil bruke. Enkelt og greit.
        Vi startet Yne med et brennende ønske om å utfordre den etablerte
        konsulentbransjens måte å tenke på. I dag er vi et fremoverlent og
        nytenkende selskap – av utviklere, for utviklere.
      </p>
    </>
  );
};

export default HomeIntroText;
