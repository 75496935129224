import React, { FC } from "react";
export const Rådgivning: FC = () => {
  return (
    <div>
      <p>
        Våre rådgivere gir deg innsikt, veiledning og løsninger som er
        skreddersydde for dine forretningsmål. Med mange års erfaring i ulike
        domener, spesielt innen netthandel og fintech, forstår vi hvilke
        digitale verktøy og strategier som kan drive vekst. Vi hjelper deg med å
        kartlegge teknologiske utfordringer, identifisere muligheter og utvikle
        en tydelig digital strategi. Gjennom vårt engasjerte partnerskap vil vi
        lede deg gjennom hele prosessen, fra idé til implementering, for å sikre
        at du får maksimal verdi av IT-tjenestene og digitaliseringen vi
        anbefaler.
      </p>
      {/* Det BV sitter med nå er rådgivning, gir råd innenfor rekruttering av Tech. Salgsarbeidet for stingray er også rådgivning der vi gir råd gjennom salgsprosessen. Ønsker å være der og gi råd overfor kunder som lurer på hva de trenger. 
Lang erfaring med utvikling, at vi gjerne ønsker å rådgi våre eksisterende og potensielt nye kunder med vår kunnskap og hvordan de best mulig løser sine utfordringer 
Tom hos alfred berg var også rådgivning og var der en kort periode og ga */}
    </div>
  );
};
