import React from "react";
import "./hamburger-menu.scss";
import { IToggleButton } from "../../interfaces/button.interfaces";

const MenuButton = ({ handleClick, toggle }: IToggleButton) => {
  const styles = {
    lineTop: {
      transform: toggle ? "rotate(45deg)" : "none",
      transformOrigin: "top left",
      marginBottom: "5px",
    },
    lineMiddle: {
      opacity: toggle ? 0 : 1,
      transform: toggle ? "translateX(-16px)" : "none",
    },
    lineBottom: {
      transform: toggle ? "translateX(-1px) rotate(-45deg)" : "none",
      transformOrigin: "top left",
      marginTop: toggle ? "10px" : "5px",
    },
  };

  return (
    <button className="menu-button" onClick={handleClick} aria-label="menu">
      <div className="menu-button__line" style={{ ...styles.lineTop }} />
      <div className="menu-button__line" style={{ ...styles.lineMiddle }} />
      <div className="menu-button__line" style={{ ...styles.lineBottom }} />
    </button>
  );
};

export default MenuButton;
