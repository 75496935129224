import React, { Suspense, useEffect, useState } from "react";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { gapi } from "gapi-script";
import styles from "./internal-page.module.scss";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

const InternalPageLoggedin = React.lazy(() => import("./logged-in"));
const GOOGLE_LOGIN_TOKEN = process.env.REACT_APP_GOOGLE_LOGIN_TOKEN;

const InternalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [googleProfile, setProfile] = useState<GoogleLoginResponse>();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_LOGIN_TOKEN,
        scope: "profile",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ("googleId" in res) {
      setProfile(res as GoogleLoginResponse);
    }
  };

  const onFailure = (err: unknown) => {
    console.error("failed:", err);
  };

  useEffect(() => {
    if (
      googleProfile &&
      googleProfile.tokenId &&
      (location.pathname === "/intern" || location.pathname === "/intern/")
    ) {
      navigate("secure/om-deg");
    }
  }, [googleProfile]);

  return (
    <>
      <Routes>
        <Route
          path="secure/*"
          element={
            googleProfile ? (
              <Suspense>
                <InternalPageLoggedin profile={googleProfile} />
              </Suspense>
            ) : null
          }
        />
      </Routes>

      {!googleProfile && (
        <div className={styles.loginWrapper}>
          <GoogleLogin
            clientId={GOOGLE_LOGIN_TOKEN as string}
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            isSignedIn={true}
          />
        </div>
      )}
    </>
  );
};

export default InternalPage;
