import React, { FC } from "react";
import Image from "../../../components/image";
export const Frontend: FC = () => {
  return (
    <div>
      <p>
        Ofte deler man opp programvare i backend og frontend. Frontend er
        programvaren som brukeren ofte ser. Det er koden for det visuelle og
        rammeverket som trigger hva som skjer når du bruker elementer på en
        nettside, for eksempel når du fyller inn et kontaktskjema. Våre
        utviklere har erfaring og kompetanse innen en rekke rammeverk og
        bibliotek, og vi hjelper gjerne til med å velge rett teknisk plattform.
        Vi er også eksperter på visuell utforming, inkludert design av logo,
        identitetsdesign og branding, UX-design, UI-design og universell
        utforming. I tillegg er vi dyktige på illustrasjoner og
        informasjonsgrafikk. Hvis det er behov for vedlikehold av eldre
        applikasjoner, kan vi også hjelpe med dette
      </p>
      <p>
        Hvis det er behov for vedlikehold av eldre applikasjoner er dette også
        noe vi kan levere. Et frontend prosjekt blir ofte best hvis vi også
        leverer design. Har du spørsmål rundt utviklingen av frontend eller har
        et prosjekt du ønsker å få en pris på? Ta kontakt med oss!
      </p>
      <h2>Prosjektomfang Sector Alarm</h2>
      <p>
        Sector Alarm har i 25 år vært en av Europas ledende
        sikkerhetsleverandører, og beskytter mer enn 650 000 hjem og bedrifter
        over hele kontinentet.
      </p>
      <p>
        Med den raske teknologiske utviklingen og økningen i antall enheter, har
        behovet for at brukerne kan sjekke status og utføre handlinger på sitt
        hjem og alarmsystem via både app og nettleser, økt betydelig.
      </p>
      <p>
        Yne har bistått Sector Alarm med å videreutvikle deres
        Minside-webplattform. Dette systemet gir brukerne muligheten til å
        administrere alarmsystemet sitt direkte fra nettleseren. De siste årene
        har alarmsystemene blitt utvidet med stadig flere enheter, som brukerne
        må kunne sjekke status på, konfigurere og sette opp varslinger for.
      </p>
      <p>
        Eksempler på slike enheter inkluderer fuktsensorer, smarte plugger og
        temperaturmålere, for å nevne noen.
      </p>
      <p>
        Det er avgjørende at dette systemet er intuitivt og raskt, slik at
        brukerne får en optimal opplevelse og kan utføre nødvendige handlinger
        når de trenger det.
      </p>
      <p>
        Frontend-løsningen hos Sector Alarm er bygget med React og blir jevnlig
        oppdatert med tekniske forbedringer. Det er essensielt at systemet
        oppfyller dagens krav til sikkerhet og ytelse, for å sikre en pålitelig
        og effektiv tjeneste.
      </p>
      <Image
        src="https://res.cloudinary.com/yneno/image/upload/v1717862902/cases/sector-tharin-august_j9holk.jpg"
        alt="Tharin og August var blant konsulentene fra Yne hos Sector Alarm"
      />
    </div>
  );
};
