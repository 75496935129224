import React, { MouseEvent, useEffect, useRef, useState } from "react";
import styles from "./modal.module.scss";
import PrimaryButton from "../buttons/primary-button";

const isClickInsideRectangle = (e: MouseEvent, element: HTMLElement) => {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
};

type Props = {
  title: string;
  children: React.ReactNode;
  buttonText: string;
};

const Modal = ({ title, children, buttonText }: Props) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (isOpened) {
      ref.current?.showModal();
      document.body.classList.add("modal-open"); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpened]);

  return (
    <>
      <PrimaryButton color="green" onClick={() => setIsOpened(true)}>
        {buttonText}
      </PrimaryButton>

      <dialog
        className={styles.dialog}
        ref={ref}
        onCancel={() => setIsOpened(false)}
        onClick={(e) =>
          ref.current &&
          !isClickInsideRectangle(e, ref.current) &&
          setIsOpened(false)
        }
      >
        <h3>{title}</h3>

        {children}
        <div className={styles.button}>
          <PrimaryButton onClick={() => setIsOpened(false)}>Lukk</PrimaryButton>
        </div>
      </dialog>
    </>
  );
};

export default Modal;
