import React from "react";
import defaultStyles from "./default-list.module.scss";
import styles from "./unordered-list.module.scss";

type Props = React.HTMLProps<HTMLUListElement>;

const UnorderedList = ({ className, ...props }: Props) => (
  <ul
    className={`${defaultStyles.root} ${styles.root} ${className ?? ""}`}
    {...props}
  />
);

export default UnorderedList;
