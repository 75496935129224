import React from "react";
import styles from "./title.module.scss";

interface Props {
  title: string;
}

const UnderlinedTitle = ({ title }: Props) => (
  <h1 className={styles.underlineTitle}>{title}</h1>
)

export default UnderlinedTitle