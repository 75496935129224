import React, { FC } from "react";
import styles from "./radio-button.module.scss";
import { IRadioButtonProps } from "../../interfaces/input.interfaces";

const RadioButton: FC<IRadioButtonProps> = ({ onChange, label, name, value, defaultChecked }) => (
  <label className={styles.container}>
    <input
      className={styles.radioButton}
      type="radio"
      value={value}
      onChange={onChange}
      name={name}
      defaultChecked={defaultChecked}
    />
    {label}
    <span className={styles.checkmark}></span>
  </label>
);

export default RadioButton;
