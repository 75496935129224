import React from "react";
import { NavLink } from "react-router-dom";
import { MENU_ITEMS } from "../../helpers/const";
import { IToggleButton } from "../../interfaces/button.interfaces";

const SideMenu = ({ toggle, handleClick }: IToggleButton) => {
  const styles = {
    container: {
      height: toggle ? "100%" : 0,
    },
  };
  return (
    <>
      {toggle && (
        <div style={styles.container} className="side-menu">
          <ul>
            {MENU_ITEMS.map(({ link, name }) => (
              <li key={name}>
                <NavLink
                  to={link}
                  className="side-menu-links"
                  onClick={handleClick}
                >
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default SideMenu;
