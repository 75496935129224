import React, { useEffect, useState } from "react";
import styles from "./instagram-feed.module.scss";

const InstagramFeed = () => {

  const [instagramPosts, setInstagramPosts] = useState([]);

  useEffect(() => {
    getInstagramPictures();
  }, []);

  const INSTAGRAM_TOKEN = process.env.REACT_APP_INSTAGRAM_TOKEN;

  const getInstagramPictures = async () => {
    await fetch(
      `https://graph.instagram.com/me/media?fields=id,media_url&access_token=${INSTAGRAM_TOKEN}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInstagramPosts(data.data.slice(0, 30));
      })
      // This error logging helps in production. For example, the token might be expired.
      .catch((error) =>
        console.error("Request to Instagram feed failed:", error)
      );
  };

  const videoImageRender = (id: string, media_url: string) => {
    if (media_url.includes("https://video.cdninstagram.com")) {
      return (
        <video key={id} width="330" height="330" loop controls muted>
          <source src={media_url} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <img
          key={id}
          src={media_url}
          loading="lazy"
          width="330"
          height="330"
          alt=""
        />
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      {instagramPosts &&
        instagramPosts.map(({ id, media_url }: any) =>
          videoImageRender(id, media_url)
        )}
    </div>
  );
};

export default InstagramFeed;
