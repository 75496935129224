import React from "react";
import { useParams } from "react-router-dom";
import { urlFor } from "../../helpers/functions";
import styles from "./project.module.scss";
import { useProject } from "./projects.queries";
import { ProjectList } from "./components/project-list";
import { InnerLink } from "../../components/links/";
import {
  ProjectDetailQuote as Quote,
  ProjectDetailSection as Section,
} from "./projects.types";
import Image from "../../components/image";
import YoutubeEmbed from "../../components/youtube-embed";
import ProjectQuote from "./components/ProjectQuote";
import ProjectMetaHeader from "./components/ProjectMetaHeader";

export function Project() {
  const { slug } = useParams();
  const projectQuery = useProject(slug);

  if (projectQuery.isSuccess) {
    const project = projectQuery.data[0];

    return (
      <>
        <ProjectMetaHeader queryData={projectQuery.data} />
        <article className={styles.root}>
          <div className={styles.blueBackground}>
            <section
              className={`section-container ${styles.header} ${styles.sectionSpacing}`}
            >
              <article className={styles.headerInfo}>
                <h1>{project.title}</h1>
                <p>{project.description}</p>
                <dl className={styles.headerInfoDetails}>
                  <div>
                    <dt>Team</dt>
                    <dd>
                      {project.details.teamSize} konsulent
                      {project.details.teamSize > 1 && "er"}
                    </dd>
                  </div>
                  <div>
                    <dt>Periode</dt>
                    <dd>{project.details.period}</dd>
                  </div>
                  <div>
                    <dt>Lokasjon</dt>
                    <dd>{project.details.location}</dd>
                  </div>
                </dl>
              </article>
              <aside className={styles.headerImage}>
                {project.video ? (
                  <YoutubeEmbed embedId={project.video} />
                ) : (
                  <Image
                    src={urlFor(project.mainImage.asset.url)
                      .size(600, 400)
                      .url()}
                    alt={project.mainImage.caption}
                  />
                )}
              </aside>
            </section>
          </div>

          {project.sections.map(
            (s) =>
              isValidSection(s) && (
                <section
                  className={`section-container ${styles.section} ${styles.sectionSpacing}`}
                  key={s.textLeft.slice(0, 8)}
                >
                  <div>
                    <h3>{s.titleLeft}</h3>
                    <p>{s.textLeft}</p>
                  </div>

                  {s.titleRight && s.textRight && (
                    <div>
                      <h4>{s.titleRight}</h4>
                      <p>{s.textRight}</p>
                    </div>
                  )}

                  {s.image && (
                    <figure>
                      <Image
                        src={urlFor(s.image.asset.url).width(1170).url()}
                      />
                      <figcaption>{s.image.caption}</figcaption>
                    </figure>
                  )}
                </section>
              )
          )}

          <ProjectQuote />

          <section className={`section-container ${styles.sectionSpacing}`}>
            <div className={styles.bigButton}>
              <InnerLink link="/prosjekter" name="Flere prosjekter" />
            </div>
            <ProjectList
              columns={3}
              limit={3}
              filterFn={(p) => p._id !== project._id}
              sortFn={() => 0.5 - Math.random()}
            />
          </section>
        </article>
      </>
    );
  }

  return null;
}

export function isValidQuote(quote?: Quote): quote is Quote {
  if (quote && quote.text && quote.quoteeName && quote.quoteeTitle) {
    return true;
  }

  return false;
}

function isValidSection(section: Section): section is Section {
  if (section.titleLeft && section.textLeft) {
    return true;
  }

  console.error("This is not a valid section:", section);
  console.error(
    "An section need at least a leftTitle and a leftText property."
  );

  return false;
}
