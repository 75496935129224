import React, { ElementType } from "react";
import { useProjects } from "../projects.queries";
import { Project } from "../projects.types";
import { ProjectListItem } from "./project-list-item";
import styles from "./project-list.module.scss";
import listItemStyles from "./project-list-item.module.scss";

interface Props {
  columns?: number;
  limit?: number;
  filterFn?: (project: Project) => unknown;
  sortFn?: (project: Project) => number;
  headerAs?: ElementType;
}

export function ProjectList({
  columns = 2,
  limit,
  filterFn,
  sortFn,
  headerAs = "h3",
}: Props) {
  const query = useProjects();

  return (
    <div
      className={styles.root}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {query.isLoading ? (
        [0, 1, 2, 3].map((i) => (
          <div key={i}>
            <div className={styles.skeletonImage} />
            <div className={styles.skeletonText} />
            <div className={styles.skeletonText} style={{ width: "100%" }} />
            <div className={styles.skeletonText} style={{ width: "75%" }} />
          </div>
        ))
      ) : query.isSuccess ? (
        query.data
          .filter((p) => (filterFn ? filterFn(p) : p))
          .sort((p) => (sortFn ? sortFn(p) : 0))
          .slice(0, limit)
          .map((project) => (
            <ProjectListItem
              headerAs={headerAs}
              key={project._id}
              title={project.title}
              keyInfo={project.keyInfo}
              description={project.description}
              detailLink={
                project.shouldShowDetails ? project.slug.current : undefined
              }
              image={project.mainImage.asset.url}
              imageCaption={project.mainImage.caption}
              className={
                columns === 1
                  ? listItemStyles.alternateSingleRow
                  : listItemStyles.projectPreview
              }
            />
          ))
      ) : (
        <p>Noe gikk galt!</p>
      )}
    </div>
  );
}
