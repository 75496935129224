import React from "react";
import { BlockContent } from "../../helpers/sanity";
import { Block } from "../../interfaces/handbook-blocks.interfaces";
import UnderlinedTitle from "../title";
import styles from './handbook-blocks.module.scss'
import { BACKGROUND_RIGHT_VARIANTS, BACKGROUND_LEFT_VARIANTS } from "../../helpers/const";

export const TextBlock = (data : Block) => {
  const { title, bodyLeft, bodyRight, backgroundPosition } = data;

  return (
    <div className={styles.textBlock} id={title}>
      <div className={BACKGROUND_LEFT_VARIANTS.includes(backgroundPosition || '')  ? styles.coloredBackground : ''}>
        { title && <UnderlinedTitle title={title} /> }
        <BlockContent blocks={bodyLeft} />
      </div>
      <div className={BACKGROUND_RIGHT_VARIANTS.includes(backgroundPosition || '') ? styles.coloredBackground : ''}>
        { title && <UnderlinedTitle title={''} /> }
        <BlockContent blocks={bodyRight} />
      </div>
    </div>
  )
}