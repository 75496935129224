import imageUrlBuilder from "@sanity/image-url";
import { format, formatDistanceToNow, isBefore } from "date-fns";
import { nb } from "date-fns/locale";
import sanityClient from "./client";
const builder = imageUrlBuilder(sanityClient);

export function urlFor(source: any): any {
  return builder.image(source);
}

export function assembleTeam(theProjectType: string): any {
  switch (theProjectType) {
    case "ecommerce":
      return [
        {
          label: "Frontend Developer",
          value: "frontend",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
        {
          label: "Backend Developer",
          value: "backend",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
      ];
    case "mobileapp":
      return [
        {
          label: "Cross platform mobile app Developer",
          value: "cross",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
        {
          label: "Backend Developer",
          value: "backend",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
      ];
    case "webapp":
      return [
        {
          label: "Frontend Developer",
          value: "frontend",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
        {
          label: "Backend Developer",
          value: "backend",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
      ];
    case "landingpagecms":
      return [
        {
          label: "Frontend Developer",
          value: "frontend",
          currentLocation: {
            title: "Remote (Ukraina)",
            value: "remote",
          },
          location: [
            {
              title: "Remote (Ukraina)",
              value: "remote",
            },
            {
              title: "Norge",
              value: "norway",
            },
          ],
          quantity: 1,
        },
      ];
    default:
      return [];
  }
}

export function additionalMembers(checkedItems: any): any {
  const answer = [
    {
      label: "Teamlead",
      value: "teamlead",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: checkedItems.teamLead ? 1 : 0,
    },
    {
      label: "UX/UI Designer",
      value: "desiner",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: checkedItems.projectManager ? 1 : 0,
    },
    {
      label: "Project manager",
      value: "pm",
      currentLocation: {
        title: "Remote (Ukraina)",
        value: "remote",
      },
      location: [
        {
          title: "Remote (Ukraina)",
          value: "remote",
        },
        {
          title: "Norge",
          value: "norway",
        },
      ],
      quantity: checkedItems.desiner ? 1 : 0,
    },
  ];

  return answer;
}

export function teamCost({ team, additionalInfo }: any): number {
  let cost = 0;
  let teamCost = 0;

  const additionalInfoLength = Object.keys(additionalInfo).length;
  cost += additionalInfoLength * 100;

  team.map((member: any) => {
    let memberCost = 750;
    if (member.currentLocation.value === "norway") memberCost = 1200;
    teamCost += member.quantity * memberCost;
    return teamCost;
  });

  cost += teamCost;

  return cost;
}

export function filteredTeam(team: any, theProjectType: string): any {
  const baseTypes: any = {
    ecommerce: ["frontend", "backend"],
    webapp: ["frontend", "backend"],
    mobileapp: ["cross", "backend"],
    landingpagecms: ["frontend"],
    additional: ["teamlead", "desiner", "pm"],
  };

  const options = baseTypes[theProjectType];
  let filteredTeam = team;
  for (const option in options) {
    filteredTeam = filteredTeam.filter(
      (item: any) => item.value !== options[option]
    );
  }

  return filteredTeam;
}

export function filterTeamMemberLocation(team: any, title: string): any {
  const answer = team
    .map(
      (member: any) => member.value === title && member.currentLocation.title
    )
    .filter((item: any) => item !== false);
  return answer.toString();
}

export function filterTeamMemberQuantity(team: any, title: string): any {
  const answer = team
    .map((member: any) => member.value === title && member.quantity)
    .filter((item: any) => item !== false);
  return answer.toString();
}

export function findMemberIndex(team: any, index: number): number {
  const order: any = {};
  let tempLength = team.length;
  for (let i = 0; i < team.length; i++) {
    tempLength--;
    order[i] = tempLength;
  }
  return order[index];
}

export function validateEmail(email: string): boolean {
  const fakeMailDoainList = [
    "memsg.top",
    "meagainapp.com",
    "cosnx.com",
    "walmartwebs.com",
    "scoresc.org",
    "cuffa.com",
    "summitgg.com",
    "bvzoonm.com",
    "skvorets.com",
    "stopelevendentalgroup.com",
    "dafam88.com",
    "adfits.com",
    "gru.company",
    "secinvaders.org",
    "kustermail.com",
    "gug.la",
    "onetruemail.info",
    "mememail.cloud",
    "quicksendr.fun",
    "rossent.club",
    "suprmailer.uno",
    "bobsmail.site",
    "rondamailx.best",
    "foxiomail.com",
    "omailer.xyz",
    "markmail.site",
    "tech-guru.site",
    "hubbu.online",
    "onlinemaster.xyz",
    "zadder.xyz",
    "sociallifes.club",
    "anomail.club",
    "riddle.media",
    "emvil.com",
    "gru.company",
    "bongo.cf",
    "cuffa.com",
    "maliberty.com",
    "dafam88.com",
    "otsonointi.org",
    "therebecas.com",
    "gutmorgen.moscow",
    "gug.la",
    "amazinglowprices.com",
    "oormi.com",
    "scoresc.org",
    "greendike.com",
    "mailree.live",
    "gurumail.xyz",
    "hqqrx@mailpoly.xyz",
    "pashter.com",
  ];
  let formalCheck = false;
  let disposableChek = true;
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) formalCheck = true;
  for (const domain of fakeMailDoainList) {
    if (email.includes(domain)) disposableChek = false;
  }

  return formalCheck && disposableChek ? true : false;
}

export function submitHubspotForm(email: string): any {
  const xhr = new XMLHttpRequest();
  const url =
    "https://api.hsforms.com/submissions/v3/integration/submit/8723582/fc4c0cdf-3ba3-4e72-9ba0-4a6240259692";

  const data = {
    submittedAt: new Date(),
    fields: [
      {
        name: "email",
        value: email,
      },
    ],
  };

  const final_data = JSON.stringify(data);

  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.send(final_data);
}

export function resolveContractEndEntry(dateString?: string) {
  if (dateString) {
    // Dates should use the norwegian format with full years -> 24/12/2022
    const [day, month, year] = dateString.split("/").map((n) => Number(n));
    const date = new Date(year, month - 1, day); // month - 1 because it's monthIndex
    const hasExpired = isBefore(date, Date.now());

    return [
      `kontrakten ${hasExpired ? "utgikk" : "utgår"}`,
      formatDistanceToNow(date, {
        addSuffix: true,
        locale: nb,
      }),
      format(date, "dd/MM/yyyy"),
    ];
  }
}

export function formatSalary(salary: number): string {
  const currencyFractionDigits = new Intl.NumberFormat('nb-no', {
    style: 'currency',
    currency: 'NOK',
  }).resolvedOptions().maximumFractionDigits;

  const formatedSalary = (salary).toLocaleString('nb-no', {
    maximumFractionDigits: currencyFractionDigits 
  });

  return `${formatedSalary} kr/år`;
}

export type specialEvent = "pride"|"christmas"|"newyear"|""

export const specialLogoVariable = (): specialEvent => {
  const now = new Date();
  

  const prideStart = new Date()
  prideStart.setMonth(5, 1);
  const prideEnd = new Date();
  prideEnd.setMonth(6,2)

  const christmasStart = new Date()
  christmasStart.setMonth(11, 1);
  const christmasEnd = new Date();
  christmasEnd.setMonth(11,30)

  const newYearStart = new Date()
  newYearStart.setMonth(11, 31);
  const newYearEnd = new Date();
  newYearEnd.setFullYear(newYearEnd.getFullYear()+1)
  newYearEnd.setMonth(0,1)


  if(now.valueOf() >= prideStart.valueOf() && now.valueOf()<= prideEnd.valueOf()){
    return "pride"
  }
  if(now.valueOf() >= christmasStart.valueOf() && now.valueOf()<= christmasEnd.valueOf()){
    return "christmas"
  }
  if(now.valueOf() >= newYearStart.valueOf() && now.valueOf()<= newYearEnd.valueOf()){
    return "newyear"
  }
  return ""
}