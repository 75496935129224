import React, { useState } from "react";
import { SERVICE_INFO } from "../../helpers/const";
import styles from "./home.module.scss";
import itemStyles from "../../components/service-item/service-item.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import SwipeIcon from "../../assets/icons/swipe-right.png";
import RefreshSolidIcon from "../../assets/icons/refreshSolid.png";

interface AnimationData {
  x: number;
  y: number;
}

const figureAnimations = [
  { rotate: [0, 5, 0] },
  { scale: [1, 1.05, 1] },
  { rotateX: [0, 15, 0] },
  { scale: [1, 0.95, 1] },
  { rotate: [0, -5, 0] },
  { rotateY: [0, 15, 0] },
];

const SwipeService = () => {
  const startArray: AnimationData[] = Array.from(
    Array(SERVICE_INFO.length),
    () => ({ x: 0, y: 0 })
  );

  const [animationData, setAnimationData] =
    useState<AnimationData[]>(startArray);

  const resetCards = async () => {
    const newArray: AnimationData[] = Array.from(
      Array(SERVICE_INFO.length),
      () => ({ x: 0, y: 0 })
    );
    setAnimationData(newArray);
  };

  const [showSwipe, setShowSwipe] = useState(
    localStorage.getItem("swipeConfirm") !== "OK"
  );

  const SwipeConfirm = () => {
    localStorage.setItem("swipeConfirm", "OK");
    setShowSwipe(false);
  };

  return (
    <div className={styles.service__swipeBody}>
      {SERVICE_INFO.map(({ icon, title, info }, index) => (
        <motion.div
          drag
          custom={index}
          animate={{
            x: animationData[index].x,
            y: animationData[index].y,
            rotate: index % SERVICE_INFO.length,
          }}
          initial={{
            rotate: 0,
            x: 0,
            y: 0,
          }}
          whileDrag={{ rotate: 10 }}
          onDragEnd={() => {
            const updateObj = [...animationData];
            updateObj[index].x = 1000;
            updateObj[index].y = 0;
            setAnimationData(updateObj);
          }}
          dragConstraints={{ top: 0, right: 1000, bottom: 0, left: 0 }}
          className={itemStyles.item}
          style={{ boxShadow: "1px 1px 3px lightgray", height: "310px" }}
          key={title}
        >
          <motion.div
            animate={figureAnimations[index % figureAnimations.length]}
            transition={{ repeat: Infinity, duration: 2.5 }}
          >
            <img src={icon} alt="" />
          </motion.div>
          <div className="headline-4">{title}</div>

          <div
            className={`paragraph-2 ${itemStyles.paragraph_overflow_hidden}`}
          >
            {info}
          </div>
        </motion.div>
      ))}
      <AnimatePresence>
        {showSwipe && (
          <motion.div
            drag
            className={styles.swipeButtonContainer}
            exit={{ opacity: 0 }}
            onDragEnd={() => SwipeConfirm()}
            onClick={() => SwipeConfirm()}
          >
            <motion.button
              initial={{ scale: 1, rotate: 0 }}
              animate={{ scale: [1, 1.1, 1], rotate: [0, 10, 0] }}
              transition={{ repeat: Infinity, duration: 2 }}
              className={styles.swipeButton}
              onClick={() => SwipeConfirm()}
            >
              <img src={SwipeIcon} alt="" className={styles.swipeIcon} />
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button className={styles.refreshButton} onClick={resetCards}>
        <img src={RefreshSolidIcon} alt="" className={styles.refreshIcon} />
      </motion.button>
    </div>
  );
};

export default SwipeService;
