import React from "react";
import styles from "./buttons.module.scss";

const FormButton = () => (
  <button className={`button-link-text ${styles.form}`} type="submit">
    Send
  </button>
);

export default FormButton;
