import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import sanityClient from "../../helpers/client";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import styles from "./post.module.scss";
import { MONTH_NAMES } from "../../helpers/const";
import { urlFor } from "../../helpers/functions";
import { BlockContent } from "../../helpers/sanity";
import Image from "../../components/image"

function getDate(data: any): any {
  const date = new Date(data);
  return (
    date.getDate() +
    " " +
    MONTH_NAMES[date.getMonth()] +
    ", " +
    date.getFullYear()
  );
}

const Post = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  const [postData, setPostData] = useState<any | {}>({});
  const { pathname } = useLocation();
  const shareUrl = "https://yne.no" + pathname;
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          description,
          slug,
          publishedAt,
          categories[]->,
          mainImage{
            caption,
            crop,
            hotspot,
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <>
      <Helmet>
        <title> Blogg | {`${postData.title}`} </title>
        <meta name="description" content={postData.description} />
        <meta name="author" content={postData.name}></meta>
        <meta name="article:author" content={postData.name}></meta>
        <meta
          name="article:published_time"
          content={getDate(postData.publishedAt)}
        ></meta>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://yne.no${pathname}`} />
        <meta property="og:title" content={postData.title} />
        <meta property="og:description" content={postData.description} />
        <meta
          property="og:image"
          content={urlFor(postData.mainImage).width(640).height(640).url()}
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://yne.no${pathname}`} />
        <meta property="twitter:title" content={postData.title} />
        <meta property="twitter:description" content={postData.description} />
        <meta
          property="twitter:image"
          content={urlFor(postData.mainImage).width(640).height(640).url()}
        />

        <meta
          property="og:site_name"
          content={`Yne | Blogg | ${postData.title}`}
        />
        <meta name="twitter:image:alt" content={postData.title} />
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.title_wrapper}>
          <p className={styles.category_title}>
            {postData.categories && postData.categories[0]?.title}
          </p>
          <h1>{postData.title}</h1>
        </div>
        <figure>
          <Image
            src={urlFor(postData.mainImage).width(1920).height(1500).url()}
            alt=""
            className={styles.main_image}
          />
          {postData.mainImage && (
            <figcaption>{postData.mainImage.caption}</figcaption>
          )}
        </figure>
        <div className={styles.share_wrapper}>
          <div className={styles.share}>
            <p className="paragraph-2">DEL</p>
            <FacebookShareButton url={shareUrl} quote={postData.title}>
              <FacebookIcon size={36} round={true} />
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl} title={postData.title}>
              <LinkedinIcon size={36} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton url={shareUrl} title={postData.title}>
              <TwitterIcon size={36} round={true} />
            </TwitterShareButton>
          </div>
        </div>
        <div className={styles.content}>
          <BlockContent blocks={postData.body} />
          <div className={styles.post_info}>
            <span>
              {postData.authorImage && (
                <img
                  src={urlFor(postData.authorImage).width(60).crop("entropy")}
                  alt=""
                />
              )}
              {postData.name && <p>{postData.name}</p>}
            </span>
            {postData.publishedAt && <p>{getDate(postData.publishedAt)}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
