import React, { FC } from "react";
import Refractor from "react-refractor";
import js from "refractor/lang/javascript";
import ts from "refractor/lang/typescript";
import kotlin from "refractor/lang/kotlin";
import java from "refractor/lang/java";
import json from "refractor/lang/json";
import yaml from "refractor/lang/yaml";
import csharp from "refractor/lang/csharp";
import "./theme.css";

Refractor.registerLanguage(js);
Refractor.registerLanguage(ts);
Refractor.registerLanguage(kotlin);
Refractor.registerLanguage(java);
Refractor.registerLanguage(json);
Refractor.registerLanguage(yaml);
Refractor.registerLanguage(csharp);

interface Props {
  language: string;
  code: string;
}

const Code: FC<Props> = ({ language, code }) => (
  <Refractor language={language} value={code} />
);

export default Code;
