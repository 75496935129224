import React from "react";
import JobPage from "../job";
import { CLOUDINARY_IMG } from "../../helpers/const";
const SubTitle = () => (
  <>
    <h3>Ønsker du å høre hvordan vår modell kan tilpasses din arbeidsstil?</h3>
    <h3>
      Ta kontakt for en utforpliktende prat og få dypere innsikt i hvordan vår
      løsning kan være relevant og verdifull for deg 📱
    </h3>
  </>
);
const Frilans = () => (
  <JobPage
    title="En unik modell for deg som trives i frilansverdenen 🚀"
    subtitle={<SubTitle />}
    socialMediaDesc="Frilans utvikler? Intressert i provisjonsbasert lønn og et selskap som har ryggen din?"
    ctaButtonText="Se ledige stillinger"
    imageSrc={CLOUDINARY_IMG.JOB.frilans}
  />
);

export default Frilans;
