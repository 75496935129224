import React from "react";
import { BlockContent } from "../../helpers/sanity";
import { Block } from "../../interfaces/handbook-blocks.interfaces";
import UnderlinedTitle from "../title";
import styles from "./handbook-blocks.module.scss";
import {
  BACKGROUND_RIGHT_VARIANTS,
  BACKGROUND_LEFT_VARIANTS,
  BACKGROUND_IMAGE_VARIANTS,
} from "../../helpers/const";
import { SanityTable } from "../tables/sanity-table";
import { urlFor } from "../../helpers/functions";

export const TextBlockWithTable = (data: Block) => {
  const { title, bodyLeft, bodyRight, backgroundPosition, table, image } = data;

  return (
    <div className={`${styles.textBlock} ${styles.tableBlock}`} id={title}>
      <div
        className={
          BACKGROUND_LEFT_VARIANTS.includes(backgroundPosition || "")
            ? styles.coloredBackground
            : ""
        }
      >
        {title && <UnderlinedTitle title={title} />}
        <div
          className={`${styles.imageWrapper} ${
            BACKGROUND_IMAGE_VARIANTS.includes(backgroundPosition || "")
              ? styles.coloredBackground
              : ""
          }`}
        >
          {image && (
            <img
              src={urlFor(image.asset).auto("format").fit("max").width(620)}
              alt={"data.caption"}
              loading="lazy"
            />
          )}
        </div>
        <div className={styles.center}>
          {table && <SanityTable {...table} />}
        </div>
      </div>
      <div
        className={
          BACKGROUND_RIGHT_VARIANTS.includes(backgroundPosition || "")
            ? styles.coloredBackground
            : ""
        }
      >
        {title && <UnderlinedTitle title={""} />}
        <BlockContent blocks={bodyLeft} />
        <BlockContent blocks={bodyRight} />
      </div>
    </div>
  );
};
