import React from "react";
import styles from "./value-point.module.scss";
import Image from "../../../components/image";

interface Props extends React.PropsWithChildren {
  title: string;
  imgsrc: string;
}
const ValuePoint = ({ title, children, imgsrc }: Props) => {
  return (
    <div className={`${styles.wrapper}`}>
    <Image src={imgsrc} className={styles.image}/>
    <div className={styles["content-wrapper"]}>
    <h3>{title}</h3>
    {children}
    </div>
    
    </div>
  );
};

export default ValuePoint;
