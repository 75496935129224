import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  CONTACTS_OFFICE,
  CONTACTS_PHONE,
  CONTACTS_MAIL,
  TEAM_TO_CONTACT,
} from "../../helpers/const";
import { NavLink } from "react-router-dom";
import styles from "./contact-us.module.scss";
import { BaseInput, TextAreaInput } from "../../components/inputs";
import { FormButton } from "../../components/buttons";
import crose from "../../assets/icons/crose.svg";
import SimpleMap from "../../components/map";
import { ContactMember } from "../../components/cards";

const ContactUs = () => {
 
  const [msg, setMsg] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;

    const formData = {
      companyname: form.companyname.value,
      phonenumber: form.phonenumber.value,
      name: form.name.value,
      email: form.email.value,
      melding: form.melding.value,
    };

    function encode(data: any) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => setMsg("Takk for at du tok kontakt med oss!"))
      .catch((error) => alert(error));
  };

  const handleClick = () => setMsg("");

  return (
    <>
      <Helmet>
        <title>Kontakt oss | Yne</title>
        <meta
          name="description"
          content="Hvis har behov for design eller utvikling, ønsker vi gjerne å høre fra deg. Kontakt oss her."
        />
      </Helmet>
      {msg && (
        <div className={styles.success_wrapper}>
          <div className={styles.success_message}>
            <NavLink to="/" onClick={handleClick}>
              <img src={crose} alt="close" />
            </NavLink>
            <h1>{msg}</h1>
            <p>
              Vi setter pris på at du kontaktet oss. En av våre kolleger vil
              straks ta kontakt med deg!
            </p>

            <p>Ha en fortsatt fin dag!</p>
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <h2>Kontakt oss</h2>
        <p>
          Hvis du har behov for design eller utvikling, ønsker vi gjerne å høre
          fra deg. Vennligst fyll ut skjemaet under.
        </p>
        <div className={styles.contact}>
          <div className={`colored-background ${styles.message}`}>
            <div>
              <h3>Send melding</h3>
              <form onSubmit={handleSubmit} name="contact" method="post">
                <BaseInput label="Firmanavn *" name="companyname" required />
                <BaseInput label="Mobil *" name="phonenumber" type="tel" required />
                <BaseInput label="Navn *" name="name" required />
                <BaseInput label="Epost *" name="email" type="email" required />
                <TextAreaInput label="Melding *" name="melding" required/>
                <input type="hidden" name="form-name" value="contact" />
                <FormButton />
              </form>
            </div>
          </div>
          <div
            className={`dark-colored-background light-text ${styles.information}`}
          >
            <div>
              <h3>Kontaktinformasjon</h3>
              <ul className={`footer__bottom--contacts contacts-office`}>
                <h3 className="contact-text">Addresse</h3>
                {CONTACTS_OFFICE.map((item) => (
                  <li key={item} className="paragraph-2">
                    {item}
                  </li>
                ))}
              </ul>
              <ul className={`footer__bottom--contacts contacts-mail`}>
                <h3 className="contact-text">Epost</h3>
                {CONTACTS_MAIL.map((item) => (
                  <li key={item} className="paragraph-2">
                    {item}
                  </li>
                ))}
              </ul>
              <ul className={`footer__bottom--contacts contacts-telephone`}>
                <h3 className="contact-text">Telefon</h3>
                {CONTACTS_PHONE.map((item) => (
                  <li key={item} className="paragraph-2">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.wrapper} ${styles.members}`}>
        {TEAM_TO_CONTACT.map((item) => (
          <ContactMember key={item.name} {...item} />
        ))}
      </div>
      <div className={styles.map_wrapper}>
        <div>
          <h3>Grensen 15, Oslo, Norway</h3>
          <SimpleMap lat={59.914055693392235} lng={10.742980167853574} />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
