import React, { ChangeEvent, FC } from "react";
import styles from "./slider.module.scss";

interface Props {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  min: string;
  max: string;
  step?: number;
}

const YneSlider: FC<Props> = ({ value, onChange, min, max, step }) => (
  <div className={styles.sliderWrapper}>
    <input
      className={styles.slider}
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      step={step}
      aria-label={`Fra ${min} til ${max}`}
    />
    <div className={styles.sliderLegends}>
      <div aria-hidden >{min}</div>
      <div aria-hidden>{max}</div>
    </div>
  </div>
);

export default YneSlider;
