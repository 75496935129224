import React from "react";
import { useParams } from "react-router-dom";
import { useProject } from "../projects.queries";
import { isValidQuote } from "../project";
import styles from "../project.module.scss";

type ProjectQuote = {
  route?: string;
};

const ProjectQuote = ({ route }: ProjectQuote) => {
  const { slug: routeSlug } = useParams();
  const slugToUse = route || routeSlug;
  const projectQuery = useProject(slugToUse);

  const project = !projectQuery.data ? null : projectQuery.data[0];

  return (
    <>
      {project && isValidQuote(project.quote) && (
        <div className={styles.valueDeliveredContainer}>
          <section
            className={`section-container ${styles.valueDelivered} ${styles.sectionSpacing}`}
          >
            <div>
              <h4 className={styles.projectQuoteTitle}>Verdi levert</h4>
              <p>{project.quote.text}</p>

              <div className={styles.valueDeliveredSeparator}>
                <div />
                <div />
              </div>

              <p>
                <b>{project.quote.quoteeName}</b> - {project.quote.quoteeTitle}
              </p>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ProjectQuote;
