import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import styles from "./salary-calculator.module.scss";
import YneSlider from "../../components/slider";
import RadioButton from "../../components/radio-button";
import CalculationResult from "./calculationResult";
import {
  CLOUDINARY_IMG,
  PERCENTAGE_OF_INVOICES_FOR_EMPLOYEE_SALARY,
} from "../../helpers/const";

export type EducationLevel = "Bachelorgrad" | "Mastergrad" | "Selvlært";

const Calculator = () => {
  const [yearsOfExp, setYearsOfExp] = useState(5);
  const [hourlyRate, setHourlyRate] = useState(1250);
  const [educationLevel, setEducationLevel] =
    useState<EducationLevel>("Bachelorgrad");

  const sliderMin = useMemo(
    () => (educationLevel === "Selvlært" ? 5 : 0),
    [educationLevel]
  );

  const provisionPercentage = useMemo(() => {
    let provisionLevel = yearsOfExp;
    if (educationLevel === "Bachelorgrad") {
      provisionLevel += 1;
    } else if (educationLevel === "Mastergrad") {
      provisionLevel += 2;
    }

    return PERCENTAGE_OF_INVOICES_FOR_EMPLOYEE_SALARY[
      Math.min(17, provisionLevel)
    ];
  }, [educationLevel, yearsOfExp]);

  useEffect(() => {
    if (yearsOfExp < sliderMin) {
      setYearsOfExp(5);
    }
  }, [sliderMin, yearsOfExp]);

  const onYearsOfExpSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYearsOfExp(parseInt(e.target.value));
  };

  const onHourlyRateSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHourlyRate(parseInt(e.target.value));
  };

  const onEducationLevelRadioButtonChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setEducationLevel(e.target.value as EducationLevel);
  };

  return (
    <div className={styles.calculationWrapper}>
      <div className={styles.calculatorWrapper}>
        <div>
          <img src={CLOUDINARY_IMG.HOME.team_party} alt="" />
        </div>

        <h2>Ynes lønnskalkulator</h2>

        <div className={styles.calculationOption}>
          <h3>Hvor mange års jobberfaring har du?</h3>
          <p>
            Jeg har <strong>{yearsOfExp}</strong> års jobberfaring
          </p>
          <YneSlider
            value={yearsOfExp}
            onChange={onYearsOfExpSliderChange}
            min={sliderMin.toString()}
            max="20"
          />
        </div>

        <div className={styles.calculationOption}>
          <h3>Hva vil din timepris være?</h3>
          <p>
            Timeprisen er <strong>{hourlyRate.toLocaleString()}</strong> kr pr
            time
          </p>
          <YneSlider
            value={hourlyRate}
            onChange={onHourlyRateSliderChange}
            min="700"
            max="2000"
            step={5}
          />
        </div>

        <div className={styles.calculationOption}>
          <h3>Hva slags IT-utdannelse har du?</h3>
          <div className={styles.radioGroup}>
            <RadioButton
              name="education"
              value="Mastergrad"
              onChange={onEducationLevelRadioButtonChange}
              label="Mastergrad"
            />

            <RadioButton
              name="education"
              value="Bachelorgrad"
              onChange={onEducationLevelRadioButtonChange}
              label="Bachelorgrad"
              defaultChecked
            />

            <RadioButton
              name="education"
              value="Selvlært"
              onChange={onEducationLevelRadioButtonChange}
              label="Selvlært (min. 5 års erfaring)"
            />
          </div>
        </div>
      </div>

      <CalculationResult
        yearsOfExp={yearsOfExp}
        hourlyRate={hourlyRate}
        educationLevel={educationLevel}
        provisionPercentage={provisionPercentage}
      />
    </div>
  );
};

export default Calculator;
