import React from "react";
import "./footer.scss";
import Logo from "../../assets/logo-white.svg";
import {
  CONTACTS_OFFICE,
  CONTACTS_PHONE,
  CONTACTS_MAIL,
  CONTACTS_COMPANY_NUMBER,
} from "../../helpers/const";
import { ButtonCta } from "../buttons";
import PageScrollButton from "../buttons/page-scroll-button";
import SocialLinks from '../links/social-links';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__top--scroll-cta-wrapper">
          <ButtonCta />
        </div>
        <div className="footer__top--scroll-btn-wrapper">
          <PageScrollButton />
        </div>
      </div>
      <div className="footer__bottom">
        <img src={Logo} alt="Yne" />
        <ul className={`footer__bottom--contacts contacts-office`}>
          <h3 className="headline-4">Adresse</h3>
          {CONTACTS_OFFICE.map((item) => (
            <li key={item} className="paragraph-2">
              {item}
            </li>
          ))}
        </ul>
        <ul className={`footer__bottom--contacts contacts-company-number`}>
          <h3 className="headline-4">Org.nr</h3>
          {CONTACTS_COMPANY_NUMBER.map((item) => (
            <li key={item} className="paragraph-2">
              {item}
            </li>
          ))}
        </ul>
        <ul className={`footer__bottom--contacts contacts-mail`}>
          <h3 className="headline-4">E-post</h3>
          {CONTACTS_MAIL.map((item) => (
            <li key={item} className="paragraph-2">
              {item}
            </li>
          ))}
        </ul>
        <ul className={`footer__bottom--contacts contacts-telephone`}>
          <h3 className="headline-4">Telefon</h3>
          {CONTACTS_PHONE.map((item) => (
            <li key={item} className="paragraph-2">
              {item}
            </li>
          ))}
        </ul>
        <SocialLinks />
      </div>
    </footer>
  );
};

export default Footer;
