import React from 'react'
import styles from "./sanity-table.module.scss";

export const SanityTable = ({ rows }: any) => {
    return (
        <table className={styles.tableWrapper}>
          <thead>
            <tr>
              {rows[0].cells.map((cell: string, index: number) => (
                <th key={index}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.slice(1).map((row: any) => (
              <tr key={row._key}>
                {row.cells.map((cell: string, index: number) => (
                  <td key={index}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
}
