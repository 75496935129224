import React, { ElementType, ReactNode } from "react";
import { Link } from "react-router-dom";
import { urlFor } from "../../../helpers/functions";
import styles from "./project-list-item.module.scss";
import Image from "../../../components/image";
import { InnerLink } from "../../../components/links";

interface Props {
  image: string;
  imageCaption: string;
  keyInfo: string;
  title: string;
  description: string;
  detailLink?: string;
  className?: string;
  headerAs: ElementType;
}

const Header: React.FC<{
  as?: ElementType;
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}> = ({ as: Component = "h3", children, className, style }) => {
  return (
    <Component className={className} style={style}>
      {children}
    </Component>
  );
};

export const ProjectListItem = ({
  image,
  imageCaption,
  keyInfo,
  title,
  description,
  detailLink,
  className,
  headerAs,
}: Props) => {
  const renderImage = (
    <Image
      src={urlFor(image).size(600, 400).format("jpg").url()}
      alt={imageCaption}
    />
  );

  const link = detailLink ? `/prosjekter/${detailLink}` : undefined;

  return (
    <div className={className}>
      <div className={styles.imageWrapper}>
        {link ? <Link to={link}>{renderImage}</Link> : renderImage}
      </div>
      <div>
        <p className={`paragraph-2 ${styles.keyInfo}`}>{keyInfo}</p>
        <Header as={headerAs} className={styles.title}>
          {title}
        </Header>
        <p className={styles.description}>{description}</p>
        {link && (
          <div className={styles.readMore}>
            <InnerLink link={link} name="Les mer" />
          </div>
        )}
      </div>
    </div>
  );
};
