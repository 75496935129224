import React from "react";
import styles from './aside-menu.module.scss'
export const AsideMenu = ({ menuItems, activeSection}: any) => {
  return (
    <ul className={styles.asideMenu}>
      <h3>Innhold</h3>
      {menuItems.map((item: string) => (
        item && <li key={item} className={activeSection === item ? styles.activeMenuItem : ''}>
          {item && <a href={`#${item}`}>{item}</a>}
        </li>
      ))}
    </ul>
  )
}