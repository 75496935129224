import React from "react";
import styles from "./containers.module.scss";

const ContainerWithImage = (props: any) => (
  <div className={`section-container ${styles.wrapper} ${props.className}`}>
    {props.children}
  </div>
);

export default ContainerWithImage;
