import React from "react";
import styles from "./service-item.module.scss";
import { motion } from "framer-motion";

interface ServiceItemProps {
  title: string;
  icon: string;
  info: string;
  link: string;
  index: number;
}

const figureAnimations = [
  { rotate: [0, 5, 0] },
  { scale: [1, 1.05, 1] },
  { rotateX: [0, 15, 0] },
  { scale: [1, 0.95, 1] },
  { rotate: [0, -5, 0] },
  { rotateY: [0, 15, 0] },
];

const ServiceItem = ({ title, icon, info, link, index }: ServiceItemProps) => (
  <motion.a className={styles.item} href={link}>
    <motion.div
      animate={figureAnimations[index % figureAnimations.length]}
      transition={{ repeat: Infinity, duration: 2.5 }}
    >
      <img src={icon} alt="" />
    </motion.div>
    <div>
      <h2 className={`headline-4 ${styles.headline}`}>{title}</h2>
    </div>
    <div className="paragraph-2">{info}</div>
  </motion.a>
);

export { ServiceItem };
