import React, { ChangeEvent, useState } from "react";
import styles from "./calculationResult.module.scss";
import yneOfficeIcon from "../../assets/icons/yne_office_icon.svg";
// import infoIcon from "../../assets/icons/info_icon.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "react-tabs/style/react-tabs.css";
import { range } from "lodash";
import {
  ANNUAL_NUMBER_OF_INVOICEABLE_HOURS,
  NUMBER_OF_YEARS,
  PERCENTAGE_OF_INVOICES_FOR_EMPLOYEE_SALARY,
  // teknaItAverage,
} from "../../helpers/const";
import { EducationLevel } from ".";
import { calculateYearlyCommissionSalary } from "../../utils/salary/salary";
import { formatSalary } from "../../helpers/functions";
import RadioButton from "../radio-button";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  yearsOfExp: number;
  hourlyRate: number;
  educationLevel: EducationLevel;
  provisionPercentage: number;
}

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      borderWidth: 5,
      borderColor: "#6105F8",
    },
  },
  scales: {
    x: {
      display: true,
      text: "Års erfaring",
    },
    y: {
      display: true,
      text: "Lønn",
    },
  },
};

const TableHeader = ({ withPercents = false }) => {
  return (
    <thead>
      <tr>
        <th>Erfaring</th>
        {withPercents && <th>%</th>}
        <th>Lønn</th>
      </tr>
    </thead>
  );
};

const CalculationResultRow = ({
  yearsOfExp,
  salary,
  i,
  withPercents = false,
  educationAddition = 0,
}: {
  yearsOfExp: number;
  salary: number;
  i: number;
  withPercents?: boolean;
  educationAddition?: number;
}) => {
  return (
    <tr className={yearsOfExp === i ? styles.currentExp : ""} key={i}>
      <td>{i}</td>
      {withPercents && (
        <td>
          {PERCENTAGE_OF_INVOICES_FOR_EMPLOYEE_SALARY[i + educationAddition]}%
        </td>
      )}
      <td>{formatSalary(salary)}</td>
    </tr>
  );
};

const CalculationResult = ({
  yearsOfExp,
  hourlyRate,
  educationLevel,
  provisionPercentage,
}: Props) => {
  const commissionLabels =
    educationLevel === "Selvlært" ? range(5, 18) : range(17);
  // const fixedLabels = educationLevel === "Selvlært" ? range(5, 16) : range(16);
  /* const [displayFixedSalaryDataAs, setDisplayFixedSalaryDataAs] = useState<
    "table" | "graph"
  >("graph"); */
  const [displayProvisionSalaryDataAs, setDisplayProvisionSalaryDataAs] =
    useState<"table" | "graph">("graph");
  const educationAddition = (() => {
    if (educationLevel === "Bachelorgrad") {
      return 1;
    } else if (educationLevel === "Mastergrad") {
      return 2;
    } else return 0;
  })();

  const numberOfYears = NUMBER_OF_YEARS[educationAddition];

  /* const tableGraphRadioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayFixedSalaryDataAs(e.target.value as "table" | "graph");
  }; */

  const provisjonTableGraphRadioButtonChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setDisplayProvisionSalaryDataAs(e.target.value as "table" | "graph");
  };

  /* const yearlyFixedSalary = useMemo(() => {
    return fixedSalaryTable[Math.min(16, yearsOfExp + educationAddition)];
  }, [yearsOfExp, educationAddition]); */
/*
  const calculateSalary = (index: number) => {
    return fixedSalaryTable[index + educationAddition];
  };
  */

  const commissionGraphData = {
    labels: commissionLabels,
    datasets: [
      {
        label: "Provisjonsbasert lønn",
        data: commissionLabels.map((experience) => {
          const commission =
            PERCENTAGE_OF_INVOICES_FOR_EMPLOYEE_SALARY[
              Math.min(17, experience + educationAddition)
            ];
          return calculateYearlyCommissionSalary({
            hourlyRate,
            hoursWorked: ANNUAL_NUMBER_OF_INVOICEABLE_HOURS,
            commission,
          });
        }),
      },
    ],
  };

  /* interface Dataset {
    label: string;
    data: number[];
    borderColor?: string;
  } */

  /* interface graphData {
    labels: number[];
    datasets: Dataset[];
  } */

  /*  const fixedGraphData: graphData = {
    labels: fixedLabels,
    datasets: [
      {
        label: 'Fastlønn',
        data: fixedLabels.map((experience) => fixedSalaryTable[Math.min(16, experience + educationAddition)]),
        borderColor: "#6105f8",
      } 

      {
        label: "Tekna gjennomsnitt IT",
        data: fixedLabels.map(
          (experience) =>
            teknaItAverage[Math.min(16, experience + educationAddition)]
        ),
        borderColor: "#00df9d",
      },
    ],
  }; */

  return (
    <div className={styles.resultWrapper}>
      <div className={styles.calculationDetailsWrapper}>
        <h2>Beregningen baseres på</h2>
        <div className={styles.calculationDetails}>
          <div className={styles.calculationDetailsFirstColumn}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>Jobberfaring: </p>
                  </td>
                  <td>
                    <strong>{yearsOfExp} år</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Timepris: </p>
                  </td>
                  <td>
                    <strong>{hourlyRate.toLocaleString()} kr</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Timer jobbet pr. år: </p>
                  </td>
                  <td>
                    <strong>1695 timer (100%)</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Utdannelse: </p>
                  </td>
                  <td>
                    <strong>{educationLevel}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <figure className={styles.calculationDetailsLastColumn}>
            <img src={yneOfficeIcon} alt="Yne office emoji" />
          </figure>
        </div>
      </div>
      {/* <div className={styles.informationWrapper}>
        <figure className={styles.calculationDetailsLastColumn}>
          <img src={infoIcon} alt="Info icon" />
        </figure>
        <p>
          I Yne får du provisjonsbasert lønn du velge mellom{" "}
          <strong>provisjonsbasert lønn</strong> eller <strong>fastlønn</strong>
          . Dette er helt opp til deg. Fastlønn gir forutsigbarhet.
          Provisjonsbasert lønn gir høyere lønn.
        </p>
      </div> */}
      <div className={styles.salaryTypeWrapper}>
        <Tabs selectedTabClassName={styles.selectedTab}>
          <TabList className={styles.tabList}>
            <Tab className={styles.defaultTab}></Tab>
            {/* <Tab className={styles.defaultTab}>
              <span>Fastlønn</span>
            </Tab> */}
          </TabList>

          <TabPanel>
            <div className={styles.salaryTypeContent}>
              <div className={styles.salaryResult}>
                <p>
                  Basert på beregninger vil din provisjonsbaserte lønn hos Yne
                  være
                </p>
                <p>
                  <strong className={styles.salaryPrintOut}>
                    {calculateYearlyCommissionSalary({
                      hourlyRate,
                      commission: provisionPercentage,
                      hoursWorked: ANNUAL_NUMBER_OF_INVOICEABLE_HOURS,
                    }).toLocaleString()}{" "}
                    kr/år
                  </strong>
                </p>
              </div>
              <div className={styles.salaryInfo}>
                <p>
                  Den provisjonsbaserte lønnen vil øke med antall års erfaring.
                  Se graf under for å se hvilken effekt dette vil ha for deg.
                  NB: I tillegg til dette vil man også sannsynligvis få økt
                  timepris med høyere efaring samt årlig inflasjon.
                </p>
              </div>
              <div className={styles.chartWrapper}>
                <div>
                  <RadioButton
                    name="viewTypeProvisjon"
                    value="graph"
                    onChange={provisjonTableGraphRadioButtonChange}
                    label="Graf"
                    defaultChecked={displayProvisionSalaryDataAs === "graph"}
                  />
                  <RadioButton
                    name="viewTypeProvisjon"
                    value="table"
                    onChange={provisjonTableGraphRadioButtonChange}
                    label="Tabell"
                    defaultChecked={displayProvisionSalaryDataAs === "table"}
                  />
                </div>

                {displayProvisionSalaryDataAs === "graph" && (
                  <div className={styles.chartWrapper}>
                    <label className={styles.srOnly}>
                      Linjediagram som viser lønnskurven for provisjonslønn
                    </label>
                    <Line options={chartOptions} data={commissionGraphData} />
                  </div>
                )}
                {displayProvisionSalaryDataAs === "table" && (
                  <div className={styles.tableTab}>
                    <table>
                      <TableHeader withPercents={true} />
                      <tbody>
                        {commissionGraphData.datasets[0].data.map(
                          (salary: number, i: number) => {
                            return (
                              i <= numberOfYears / 2 && (
                                <CalculationResultRow
                                  yearsOfExp={yearsOfExp}
                                  withPercents={true}
                                  salary={salary}
                                  i={commissionGraphData.labels[i]}
                                  educationAddition={educationAddition}
                                />
                              )
                            );
                          }
                        )}
                      </tbody>
                    </table>
                    <table>
                      {Boolean(educationAddition) && (
                        <TableHeader withPercents={true} />
                      )}
                      <tbody>
                        {commissionGraphData.datasets[0].data.map(
                          (salary: number, i: number) => {
                            return (
                              i > numberOfYears / 2 && (
                                <CalculationResultRow
                                  yearsOfExp={yearsOfExp}
                                  withPercents={true}
                                  salary={salary}
                                  i={commissionGraphData.labels[i]}
                                  educationAddition={educationAddition}
                                />
                              )
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>

          {/* <TabPanel>
            <div className={styles.salaryTypeContent}>
              <div className={styles.salaryResult}>
                <p>Basert på beregninger vil din fastlønn hos Yne være</p>
                <p>
                  <strong className={styles.salaryPrintOut}>
                    {yearlyFixedSalary.toLocaleString()} kr/år
                  </strong>
                </p>
              </div>
              <div className={styles.salaryInfo}>
                <p>
                  Fastlønnsmodellen tar utgangspunkt i Tekna sin
                  lønnsstatistikk, statistikk kan være litt hoppende og den
                  trengte litt ujevning for at vi kunne bruke den som en
                  lønnsmodell. Her vil du få en årslønn, denne årslønnen øker
                  forutsigbart etter hvor mange års erfaring du har.
                </p>
              </div>
              <div>
                <RadioButton
                  name="viewType"
                  value="graph"
                  onChange={tableGraphRadioButtonChange}
                  label="Graf"
                  defaultChecked={displayFixedSalaryDataAs === "graph"}
                />
                <RadioButton
                  name="viewType"
                  value="table"
                  onChange={tableGraphRadioButtonChange}
                  label="Tabell"
                  defaultChecked={displayFixedSalaryDataAs === "table"}
                />
              </div>
              {displayFixedSalaryDataAs === "graph" && (
                <div className={styles.chartWrapper}>
                  <label className={styles.srOnly}>
                    Linjediagram som viser lønnskurven for fastlønn
                  </label>
                  <Line options={chartOptions} data={fixedGraphData} />
                </div>
              )}
              {displayFixedSalaryDataAs === "table" && (
                <div className={styles.tableTab}>
                  <table>
                    <TableHeader />
                    <tbody>
                      {fixedSalaryTable.map(
                        (_, i: number) =>
                          i < numberOfYears &&
                          calculateSalary(i) && (
                            <CalculationResultRow
                              yearsOfExp={yearsOfExp}
                              salary={calculateSalary(i)}
                              i={i}
                            />
                          )
                      )}
                    </tbody>
                  </table>

                  <table>
                    <TableHeader />
                    <tbody>
                      {fixedSalaryTable.map(
                        (_, i: number) =>
                          calculateSalary(i + numberOfYears) && (
                            <CalculationResultRow
                              yearsOfExp={yearsOfExp}
                              salary={calculateSalary(i + numberOfYears)}
                              i={i + numberOfYears}
                            />
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </TabPanel> */}
        </Tabs>
      </div>
    </div>
  );
};

export default CalculationResult;
