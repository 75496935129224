import React from "react";
import MetaHeader from "../../../components/metaheader";

interface QueryData {
  queryData: {
    description: string;
    slug: {
      current: string;
    };
    title: string;
    mainImage: {
      asset: {
        url: string;
      };
    };
  }[];
}

const ProjectMetaHeader = ({ queryData }: QueryData) => {
  const { description, slug, title, mainImage } = queryData[0];

  return (
    <MetaHeader
      description={description}
      url={`https://yne.no/prosjekter/${slug.current}`}
      title={`Yne | ${title}`}
      socialMediaDescription={`Yne er en gjeng teknologer som leverer skreddersydd systemutvikling ut til sine kunder. ${description}`}
      imageUrl={mainImage.asset.url}
    />
  );
};

export default ProjectMetaHeader;
