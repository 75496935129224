import React from "react";
import styles from "./handbook-blocks.module.scss";
import UnderlinedTitle from "../title";
import { BlockContent } from "../../helpers/sanity";
import { urlFor } from "../../helpers/functions";
import { BACKGROUND_IMAGE_VARIANTS, BACKGROUND_LEFT_VARIANTS, BACKGROUND_RIGHT_VARIANTS } from "../../helpers/const";

export const BaseBlock = ({ data }: any) => {
  const { title, bodyLeft, bodyRight, backgroundPosition, image } = data;

  return (
    <>
      <div className={BACKGROUND_IMAGE_VARIANTS.includes(backgroundPosition || '') ? styles.coloredBackground : ''} id={title}>
        {image &&
          <img src={urlFor(image.asset).auto('format').fit('max').width(620)} alt={'data.caption'} loading="lazy"/>
        }
      </div>
      <div className={styles.textBlock}>
        <div className={BACKGROUND_LEFT_VARIANTS.includes(backgroundPosition || '')  ? styles.coloredBackground : ''}>
          { title && <UnderlinedTitle title={title} /> }
          <BlockContent blocks={bodyLeft} />
        </div>
        <div className={BACKGROUND_RIGHT_VARIANTS.includes(backgroundPosition || '') ? styles.coloredBackground : ''}>
          { title && <UnderlinedTitle title={''} /> }
          <BlockContent blocks={bodyRight} />
        </div>
      </div>
    </>
  )
}