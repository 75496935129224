import React, { useState } from 'react'
import styles from './mobile-menu.module.scss'
import { MenuButton } from '../hamburger-menu'

export const MobileMenu = ({ menuItems, activeSection }: any) => {
  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  return (
    <div className={styles.mobileMenu}>
        <MenuButton handleClick={toggleMenu} toggle={toggle} />
        {toggle && (
            <div className={styles.menuItemsContainer}>
                <ul>
                    {menuItems.map((item: string | undefined) => (
                        <li key={item} onClick={toggleMenu} className={activeSection === item ? styles.activeMenuItem : ''}>
                            {item && <a href={`#${item}`}>{item}</a>}
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
  )
}