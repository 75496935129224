import React, { ReactElement, ReactNode } from "react";
import styles from "./kundereferanse.module.scss";

type KundeReferanse = {
  heading?: string | ReactElement;
  children?: ReactNode;
  cardTitle: string;
  cardContent: string;
  companyRepresentative?: string;
  company?: string;
  separator?: boolean;
  footer?: boolean;
};

const KundeReferanse = ({
  children,
  heading,
  cardTitle,
  cardContent,
  companyRepresentative,
  company,
  separator,
  footer,
}: KundeReferanse) => {
  return (
    <>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <div className={styles.kundereferanse_flex_row}>
        <div className={styles.video_container}>{children}</div>
        <div className={styles.kundereferanse_value_container}>
          <div className={styles.kundereferanse_value_text}>
            <strong>{cardTitle}</strong>
            <p>{cardContent}</p>

            {separator && (
              <div className={styles.valueDeliveredSeparator}>
                <div />
                <div />
              </div>
            )}
            {footer && (
              <footer className={styles.kundereferanse_value_flex}>
                <strong>{companyRepresentative} </strong> <p> {company}</p>
              </footer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default KundeReferanse;
