import React, { useEffect, useState } from "react";
import styles from "./handbook.module.scss";
import SalaryCalculator from "../../components/salary-calculator";
import sanityClient from "../../helpers/client";
import { TextBlock, BaseBlock, TextWithImageBlock, TextBlockWithTable } from "../../components/handbook-blocks";
import { AsideMenu } from "../../components/aside-menu";
import { MobileMenu } from "../../components/mobile-menu";
import useSectionHighlight from "../../hooks/useSectionHighlight";

const PersonnelHandbook = () => {
  const [sectionData, setSectionData] = useState<any | []>([]);

  const sortBlock = (a: any, b: any) => {
    return (a.blockOrder - b.blockOrder)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "handbook"]{
            title,
            handbookBlockType[]->,
            image,
            bodyLeft,
            bodyRight,
            backgroundPosition,
            blockOrder,
            quote,
            table,
          }`
      )
      .then((data) => setSectionData(data.sort(sortBlock)))
      .catch(console.error);
  }, []);

  const activeSection = useSectionHighlight();
  const menuItems = sectionData.map((data: any) => data.title)
  // TODO: make dropdown for type of blocks
  const renderBlock = (data: any) => {
    switch (data?.handbookBlockType?.[0]?.blockName) {
      case 'SalaryCalculator':
        return <div className={`${styles.testWrapper} section`}><SalaryCalculator /></div>
      case 'TextBlock':
        return <div id={data.title} className="section">
          <TextBlock {...data} />
        </div>
      case 'TextBlockWithTable':
        return <div id={data.title} className="section">
          <TextBlockWithTable {...data} />
        </div>
      case 'TextWithImageBlock':
        return <div id={data.title} className="section">
          <TextWithImageBlock {...data} />
        </div>
      default:
        return <div id={data.title} className="section">
          <BaseBlock data={data} />
        </div>
    }
  }

  return (
    <>
      <MobileMenu menuItems={menuItems} activeSection={activeSection}/>
      <div className={styles.heroSection}>
        <h1>Personalhåndbok</h1>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          {sectionData.map((data: any) => renderBlock(data))}
        </div>
        <div className={styles.menuWrapper}>
          <AsideMenu menuItems={menuItems} activeSection={activeSection}/>
        </div>
      </div>

    </>
  );
};

export default PersonnelHandbook;