import React from "react";
import { NavLink } from "react-router-dom";
import icon_arrow from "../../assets/icons/icon_arrow.svg";
import styles from "./links.module.scss";
import { ILinkItemProps } from "../../interfaces/link.interfaces";

const InnerLink = ({ link, name, color, withArrow }: ILinkItemProps) => (
  <NavLink to={link} className={`inner-link ${styles.inner} ${color ? styles[color] : ""}`}>
    {name}
    {withArrow &&
      <img src={icon_arrow} width="33" height="27" alt="" />
    }
  </NavLink>
);

export default InnerLink;
