import React, { useState, useEffect } from "react";
import sanityClient from "../../helpers/client";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const BlockContent = require("@sanity/block-content-to-react");

const Terms = () => {
  const [terms, setTerms] = useState<any>();

  useEffect(() => {
    sanityClient
      .fetch(
        `
            *[_type == "terms"]{
                title,
                body
            }
        `
      )
      .then((data) => setTerms(data[0]))
      .catch(console.error);
  }, []);

  if (!terms) return null;

  return (
    <div className={`section-container`}>
      <h1>{terms.title}</h1>
      <BlockContent
        blocks={terms.body}
        projectId={sanityClient.clientConfig.projectId}
        dataset={sanityClient.clientConfig.dataset}
      />
    </div>
  );
};

export default Terms;
