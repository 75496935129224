import React, {CSSProperties} from 'react';
import _ from "lodash";

import styles from './prideflag.module.scss';
import { COLORS } from './constants';

export interface MyCustomCSS extends CSSProperties {
    '--billow': string;
  }

  interface COLORS {
    rainbow: string[];
    'rainbow-original': string[];
    trans: string[];
    pan: string[];
}

interface PrideFlagProps {
    variant?: 'rainbow' | 'rainbow-original' | 'trans' | 'pan';
    width?: number;
    numOfColumns?: number;
    staggeredDelay?: number;
    billow?: number;
  }

  
  
  const PrideFlag: React.FC<PrideFlagProps> = ({
    variant = 'rainbow',
    width = 50,
    numOfColumns = 10,
    staggeredDelay = 100,
    billow = 2,
  }) => {
    const colors = COLORS[variant];
  
    const friendlyWidth =
      Math.round(width / numOfColumns) * numOfColumns;
  
    const firstColumnDelay = numOfColumns * staggeredDelay * -1;
  
    return (
      <div className={styles.flag} style={{ width: friendlyWidth }}>
        {_.range(numOfColumns).map((columnIndex: number) => (
          <div
            key={columnIndex}
            className={styles.column}
            style={{
                '--billow': `${columnIndex * billow}px` as string,
                background: generateGradientString(colors),
                animationDelay: `${firstColumnDelay + columnIndex * staggeredDelay}ms`,
              } as MyCustomCSS}
          />
        ))}
      </div>
    );
  }
  
  function generateGradientString(colors: string[]): string {
    const numOfColors = colors.length;
    const segmentHeight = 100 / numOfColors;
  
    const gradientStops = colors.map((color, index) => {
      const from = index * segmentHeight;
      const to = (index + 1) * segmentHeight;
  
      return `${color} ${from}% ${to}%`;
    });
  
    return `linear-gradient(to bottom, ${gradientStops.join(', ')})`;
  }
  
  export default PrideFlag;