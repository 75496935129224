import React, { FC } from "react";
import styles from "./buttons.module.scss";
import { Props }  from "../../interfaces/button.interfaces";

interface PrimaryButtonProps extends Props{
  color?: "green"
  size?: "small"|"large";
}

const PrimaryButton: FC<PrimaryButtonProps> = ({ children, onClick, color }) => (
  <button onClick={onClick} className={`${styles["primary-button"]} ${color ? styles[color] : ""}`} type="button">
    {children}
  </button>
);

export default PrimaryButton;
