import React from "react";
import InnerLink from "../links/inner-link";
import styles from "./service-section.module.scss";

interface ServiceSectionProps {
  title: string;
  sub_title: string;
  specialization: Array<any>;
  short_desc: string;
  full_desc?: React.ReactNode;
  link: string;
  full_info: boolean;
  ServiceImage?: React.ReactNode;
}

const ServiceSection = ({
  title,
  sub_title,
  specialization,
  short_desc,
  full_desc,
  link,
  full_info,
  ServiceImage,
}: ServiceSectionProps) => (
  <>
    <div className={styles["top-section-wrapper"]}>
      <div>
        <h1 className={styles.title}>{title}</h1>
        <p className="paragraph-3">{sub_title}</p>
        <div className={styles.specialization}>
          {specialization.map(({ logo, title }) => (
            <div key={title}>
              {logo && (
                <img className={styles.image} src={logo} alt="paragraph-2" />
              )}
              <p className={styles.title}>{title}</p>
            </div>
          ))}
        </div>
        <p>{short_desc}</p>
      </div>
      {ServiceImage && (
        <div className={styles["image-wrapper"]}>{ServiceImage}</div>
      )}
    </div>

    {!full_info && (
      <>
        <p className="paragraph-3">
          Hvilken verdi kan en {title.charAt(0).toLowerCase() + title.slice(1)}{" "}
          tilegne din bedrift?
        </p>
        <p>{full_desc}</p>
      </>
    )}
    {full_info && (
      <div className={styles.link__wrapper}>
        {title === "Design" ? (
          <InnerLink
            link={link}
            name="Les mer om våre tjenester innen design her"
            withArrow
          />
        ) : (
          <InnerLink link={link} name="Les mer" withArrow />
        )}
      </div>
    )}
  </>
);

export default ServiceSection;
