import React, { FC } from "react";
import UnorderedList from "../../../components/list/unordered-list";
import Image from "../../../components/image";
export const Fullstack: FC = () => {
  return (
    <div>
      <p>
        Ved å leie en fullstack-utvikler hos Yne får du tilgang til en erfaren
        utvikler som kan takle hele utviklingsprosessen fra start til slutt.
        Våre fullstack-utviklere har omfattende kunnskap og ekspertise innen
        både frontend- og backend-teknologier, noe som gjør dem i stand til å
        skape skreddersydde digitale løsninger som møter dine spesifikke behov
        og mål.{" "}
      </p>
      <p>
        Ved å samarbeide tett med deg og din bedrift, vil vår fullstack-utvikler
        kunne forstå dine krav og ønsker grundig, og deretter designe og
        implementere løsninger som leverer verdi, innovasjon og
        konkurransefortrinn. Enten det er å utvikle en brukervennlig nettside,
        en skalerbar app eller en kompleks programvareplattform, kan du være
        trygg på at våre fullstack-utviklere vil levere resultater av høy
        kvalitet som driver din bedrift fremover i den digitale verden.{" "}
      </p>
      <h2>Prosjektomfang Ruter </h2>
      <p>
        Vi har en teknisk IT fullstack-utvikler i prosjekt hos Ruter som jobber
        med utvikling for bestillingstransport som reflekterer de oppgaver våre
        konsulenter kan gjøre for dere. Konsulentens tekniske oppgaver
        innebærer:
      </p>
      <UnorderedList>
        <li>
          Ansvarlig for applikasjoner knyttet til - Min TT (Oversiktsside for
          reisende eller pårørende for å se godkjenninger, type reiser, antall
          reiser, tilganger, m.m.).{" "}
        </li>
        <li>
          Blikk (administrasjonsverktøy for skoler og institusjoner med
          informasjon om hvilke TT-reiser som skjer over en tidsperiode).{" "}
        </li>
        <li>
          TT-admin (administrasjonsverktøy som brukes av operasjonssentralen i
          Ruter, kundesenteret og Oslo kommune/bydelene.{" "}
        </li>
        <li>
          Jeg jobber både med frontend og backend teknologier som omfatter:
          React/TypeScript, NodeJS, GraphQL, Docker, Kubernetes, Kafka, Kotlin,
          Vite, Spring Boot
        </li>
      </UnorderedList>

      <Image
        src="https://res.cloudinary.com/yneno/image/upload/v1717791388/cases/tanet-ruter_mhk74f.jpg"
        alt="Tanet jobber i prosjekt for Ruter"
      />
    </div>
  );
};
