import React from "react";
import { ProjectList } from "./components/project-list";
import MetaHeader from "../../components/metaheader";

export function Projects() {
  return (
    <>
      <MetaHeader
        description="Utforsk noen av våre prosjekter. Fra beskrivelser, teknologier og løsninger som definerer vår ekspertise"
        url="https://yne.no/prosjekter"
        title="Prosjekter | Yne"
        socialMediaDescription="Yne - Av utviklere, for utviklere!"
        imageUrl={""}
      />
      <article className="section-container" style={{ marginBottom: "8rem" }}>
        <h1 style={{ marginBottom: "4rem" }}>Våre prosjekter</h1>
        <ProjectList columns={1} headerAs={"h2"} />
      </article>
    </>
  );
}
