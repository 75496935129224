import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./service-details.module.scss";
import design from "../../assets/images/design.svg";
import mobile from "../../assets/images/mobile.svg";
import front_end from "../../assets/images/front_end.svg";
import back_end from "../../assets/images/back_end.svg";
import consultancy from "../../assets/images/consultancy.svg";
import ServiceSection from "../../components/service-section/index";
import {
  SERVICE_FRONT_END,
  SERVICE_RÅDGIVNING,
  SERVICE_BACK_END,
  SERVICE_FULLSTACK,
  SERVICE_DESIGN,
  SERVICE_DEVOPS,
} from "../../helpers/const";
import Image from "../../components/image";
import ContainerWithImage from "../../components/containers";
import MetaHeader from "../../components/metaheader";
const ServiceDetails = () => {
  const { pathname } = useLocation();

  return (
    <section className={styles.details}>
      <ContainerWithImage className={styles.container}>
        {pathname.includes("design") ? (
          <>
            <MetaHeader
              description="Yne leverer det meste du kan tenke deg innenfor design. Vi har veldig dyktige designere ved vårt kontor i Ukraina. Her får du norsk kvalitet til en veldig rimelig pris. Eksempler på design produkter vi kan levere er: Identitet branding og logo. Webdesign eller design av landingssider til kampanjebruk. Illustrasjoner og infographics. Change route for tjenester/commerce to tjenester/ecommerce"
              url="https://yne.no/tjenester/design"
              title="Tjenester | Design | Yne"
              socialMediaDescription="Et godt resultat skapes gjennom en rask og effektiv prosess, der vi tar med oss kunden inn i prosessen. Ut kommer tanker, idèer og konsepter. Våre kunder skal føle seg ivaretatt og prioritert. Våre hovedtjenester er Identitet, Web design, illustrasjoner og infographics. "
              imageUrl="https://res.cloudinary.com/yneno/image/upload/v1718009917/services/design_jwddoe.png"
            />

            <div className={styles["section-wrapper"]}>
              <ServiceSection
                {...SERVICE_DESIGN}
                full_info={false}
                ServiceImage={
                  <div className={styles.image_wrapper}>
                    <img
                      src={design}
                      alt="Yne figur som skaper unike og inspirerende designløsninger"
                    />
                  </div>
                }
              />
              <p>
                For et eksempel på hva vi kan levere på branding og logo, ta
                gjerne en titt på vår egen style guide:&ensp;
                <a
                  href="https://drive.google.com/file/d/1en-rL49oHvtYXgXz1R5lq3ufP8j8wCe_/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Styleguide.pdf
                </a>
                .
              </p>
              <p>Vi leverer:</p>
              <ul className={styles.list}>
                <li>
                  <p>Identitet branding og logodesign</p>
                </li>
                <li>
                  <p>
                    Webdesign, eller design av landingsside til kampanjebruk.
                  </p>
                </li>
                <li>
                  <p>Illustrasjoner og infographics</p>
                </li>
                <li>
                  <p>Design av presentasjon til powerpoint, keynote etc.</p>
                </li>
                <li>
                  <p>3D visualisering og animasjonsklipp</p>
                </li>
                <li>
                  <p>
                    Design til print, flyers, kataloger, magasiner og annet
                    trykt materiell.
                  </p>
                </li>
                <li>
                  <p>
                    Design til sosiale medier med tilpassede størrelser og
                    uttrykk.
                  </p>
                </li>
              </ul>
              <Image
                src="https://res.cloudinary.com/yneno/image/upload/v1718009917/services/design_jwddoe.png"
                alt="Matriale med Yne design"
              />
            </div>
          </>
        ) : pathname.includes("devops") ? (
          <>
            <MetaHeader
              description="Oppdag våre omfattende DevOps-tjenester på Yne. Fra optimalisering av infrastruktur til sømløs leveranse - vi tilbyr skreddersydde løsninger for effektiv drift og utvikling."
              url="https://yne.no/tjenester/devops"
              title="Tjenester | DevOps | Yne"
              socialMediaDescription="Oppdag våre omfattende DevOps-tjenester på Yne. Fra optimalisering av infrastruktur til sømløs leveranse - vi tilbyr skreddersydde løsninger for effektiv drift og utvikling."
              imageUrl="https://res.cloudinary.com/yneno/image/upload/v1718107470/services/Skjermbilde_2024-06-11_kl._14.01.49_eqnaar.png"
            />
            <div className={styles["section-wrapper"]}>
              <ServiceSection {...SERVICE_DEVOPS} full_info={false} />
            </div>
            <div className={styles.image_wrapper}>
              <img
                src={mobile}
                alt="Yne figur som DevOps-ekspert, skaper effektive og automatiserte IT-løsninger for smidig utvikling og drift"
              />
            </div>
          </>
        ) : pathname.includes("fullstack") ? (
          <>
            <MetaHeader
              description="Utforsk vårt brede utvalg av Fullstack-tjenester hos Yne. Vårt erfarne team skaper skreddersydde løsninger som dekker alt fra front- til backend-utvikling, for å løfte din digitale tilstedeværelse til nye høyder."
              url="https://yne.no/tjenester/fullstack"
              title="Tjenester | Fullstack | Yne"
              socialMediaDescription="Utforsk vårt brede utvalg av Fullstack-tjenester hos Yne. Vårt erfarne team skaper skreddersydde løsninger som dekker alt fra front- til backend-utvikling, for å løfte din digitale tilstedeværelse til nye høyder."
              imageUrl="https://res.cloudinary.com/yneno/image/upload/v1718009917/services/design_jwddoe.png"
            />
            <div className={styles["section-wrapper"]}>
              <ServiceSection
                {...SERVICE_FULLSTACK}
                full_info={false}
                ServiceImage={
                  <img
                    src={consultancy}
                    alt="Yne figur som full-stack utvikler, leverer tjenester fra frontend til backend"
                  />
                }
              />
            </div>
          </>
        ) : pathname.includes("frontend") ? (
          <>
            <MetaHeader
              description="Yne har dyktige frontend-utviklere. Vi kan levere en eller flere utviklere som kan integreres i ditt utviklingsteam, eller vi kan sette opp et nytt utviklingsteam for dere. Vi leverer også mindre produkter som bedriftsnettsider, landingssider, kampanjesider o.l."
              url="https://yne.no/tjenester/frontend"
              title="Tjenester | Frontend | Yne"
              socialMediaDescription="Yne har dyktige frontend-utviklere. Vi kan levere en eller flere utviklere som kan integreres i ditt utviklingsteam, eller vi kan sette opp et nytt utviklingsteam for dere. Vi leverer også mindre produkter som bedriftsnettsider, landingssider, kampanjesider o.l."
              imageUrl="https://res.cloudinary.com/yneno/image/upload/v1717862902/cases/sector-tharin-august_j9holk.jpg"
            />
            <div className={styles["section-wrapper"]}>
              <ServiceSection
                {...SERVICE_FRONT_END}
                full_info={false}
                ServiceImage={
                  <img
                    src={front_end}
                    alt="Yne figur som frontend utvikler, skaper responsive og brukervennlige webapplikasjoner"
                  />
                }
              />
            </div>
          </>
        ) : pathname.includes("raadgivning") ? (
          <>
            <MetaHeader
              description="Vi gir råd til deres utfordringer, små og store. Vi er vant til komplekse problemer. Vi kjenner teknologien og bransjen, noe som gjør oss i stand til å vite hva som skal til for å løse deres utfordringer."
              url="https://yne.no/tjenester/raadgivning"
              title="Tjenester | Rådgivning | Yne"
              socialMediaDescription="Vi gir råd til deres utfordringer, små og store. Vi er vant til komplekse problemer. Vi kjenner teknologien og bransjen, noe som gjør oss i stand til å vite hva som skal til for å løse deres utfordringer."
              imageUrl="https://res.cloudinary.com/yneno/image/upload/v1718107703/services/Skjermbilde_2024-06-11_kl._14.08.14_lih8pf.png"
            />
            <div className={styles["section-wrapper"]}>
              <ServiceSection {...SERVICE_RÅDGIVNING} full_info={false} />
            </div>
            <div className={styles.image_wrapper}>
              <img
                src={consultancy}
                alt="Yne figur som IT-rådgiver, gir skreddersydde løsninger for effektiv teknologisk implementering"
              />
            </div>
          </>
        ) : pathname.includes("backend") ? (
          <>
            <MetaHeader
              description="Yne leverer dyktige backend-utviklere etter deres behov. For langvarige prosjekter henter vi inn kompetanse tilpasset til deres ønsker. Her vil dere kunne få svært flinke utviklere til en god pris. Vi kan også levere backend-ressurser for kortvarige prosjekt ved kapasitet."
              url="https://yne.no/tjenester/backend"
              title="Tjenester | Backend | Yne"
              socialMediaDescription="Yne leverer dyktige backend-utviklere etter deres behov. For langvarige prosjekter henter vi inn kompetanse tilpasset til deres ønsker. Her vil dere kunne få svært flinke utviklere til en god pris. Vi kan også levere backend-ressurser for kortvarige prosjekt ved kapasitet."
              imageUrl="https://res.cloudinary.com/yneno/image/upload/v1717862534/cases/axo-brage-david_c0vsul.jpg"
            />
            <div className={styles["section-wrapper"]}>
              <ServiceSection
                {...SERVICE_BACK_END}
                full_info={false}
                ServiceImage={
                  <img
                    src={back_end}
                    alt="Yne figur som backend utvikler, optimaliserer serverteknologier for effektive og skalerbare løsninger"
                  />
                }
              />
            </div>
          </>
        ) : null}
      </ContainerWithImage>
    </section>
  );
};

export default ServiceDetails;
