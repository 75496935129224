import React from "react";
import styles from "./buttons.module.scss";
import { NavLink } from "react-router-dom";

const ButtonCta = () => {
  return (
    <NavLink to="/kontakt-oss" className={styles.cta}>
      <h2 className={styles.text}>Kontakt oss</h2>
    </NavLink>
  );
};

export default ButtonCta;
