import React, { useEffect } from "react";
import styles from "./salary-calculator.module.scss";
import Calculator from "../../components/salary-calculator";

const SalaryCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Calculator />
    </div>
  );
};

export default SalaryCalculator;
