import { useEffect, useState } from "react";

interface SectionOffsets {
  [key: string]: number;
}

const useSectionHighlight = () => {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sectionElements = document.querySelectorAll(".section");

      const sections: SectionOffsets = {};

      sectionElements.forEach((section) => {
        const sectionEl = section as HTMLElement;
        sections[sectionEl.id] = sectionEl.offsetTop;
      });

      const scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

      for (const [id, offset] of Object.entries(sections)) {
        if (offset - 200 <= scrollPosition) {
          setActiveSection(id);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return activeSection;
};

export default useSectionHighlight;