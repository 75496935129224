import React from "react";
import icon_arrow from "../../assets/icons/icon_arrow.svg";
import styles from "./links.module.scss";
import { ILinkItemProps } from "../../interfaces/link.interfaces";

const ViewLink = ({ link, name, withArrow }: ILinkItemProps) => (
  <a href={link} target="_blank" rel="noopener noreferrer"className={`inner-link ${styles.inner}`}>
    {name}
    {withArrow &&
    <img src={icon_arrow} alt="" />
    }
  </a>
);

export default ViewLink;
