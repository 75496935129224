import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import App from "./App";
import ScrollToTop from "./components/scroll-to-top";
import ReactGA from "react-ga";
import registerServiceWorker from "./registerServiceWorker";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
ReactGA.initialize("UA-158799217-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 20,
    },
  },
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Router basename="/">
      <ScrollToTop />
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </HelmetProvider>
    </Router>
  </React.StrictMode>
);

registerServiceWorker();
