import React from "react";
import MetaHeader from "../../components/metaheader";
import { ServiceItem } from "../../components/service-item";
import { SERVICE_INFO } from "../../helpers/const";
import styles from "./service-page.module.scss";

export function ServicePage() {
  return (
    <>
      <MetaHeader
        description="Utforsk våre tjenester. Fullstack utvikling innenfor .NET, Java/Kotlin. Frontend utvikling i React. Devops spesialister innenfor Azure."
        url="https://yne.no/tjenster"
        title="Tjenester | Yne"
        socialMediaDescription="Lær hvordan Yne kan bistå din bedrift med eksperter på utvikling. Vi hjelper deg med dine komplekse digitale problemer. Fullstack, backend, frontend, devops, devops, design og rådgivning."
        imageUrl={
          "https://res.cloudinary.com/yneno/image/upload/v1606143522/assets/main_banner_estqvr.jpg"
        }
      />
      <div className={`section-container`}>
        <div className={styles["services-description"]}>
          <div>
            <h1>Tjenester</h1>
            <p>
              Yne sin kjernekompetanse er skreddersydd teknologi. Vi skaper
              helhetlige løsninger fra bunnen av, fra A-Å.
            </p>
            <p>
              Av denne grunn har vi konsulenter som er eksperter på alt som må
              til for å oppnå dette. Les mer om hver av våre tjenester her.
            </p>
          </div>
          <img
            src="https://res.cloudinary.com/yneno/image/upload/v1606143522/assets/main_banner_estqvr.jpg"
            alt="Yne sine digitale tjenester"
          />
        </div>
        <div className={styles.services}>
          {SERVICE_INFO.map(({ icon, title, info, link }, index) => (
            <ServiceItem
              link={link}
              index={index}
              key={title}
              title={title}
              icon={icon}
              info={info}
            />
          ))}
        </div>
      </div>
    </>
  );
}
