import React, { FC, useEffect, useState } from "react";

import MetaHeader from "../../components/metaheader";
import UnorderedList from "../../components/list/unordered-list";
import { ICarouselGallery } from "../../components/carousel";
import Slider, { Settings } from "react-slick";
import Image from "../../components/image";
import styles from "./style.module.scss";
import OfficeContactForm from "../../components/contact-form/office-contact";

const OfficePage: FC = () => {
  const [gallery, setGallery] = useState<Array<ICarouselGallery>>([]);

  const sortImages = (a: ICarouselGallery, b: ICarouselGallery) => {
    const aValue = parseInt(a.public_id.replace("kontorutleie/", ""));
    const bValue = parseInt(b.public_id.replace("kontorutleie/", ""));
    return aValue - bValue;
  };

  const settings: Settings = {
    infinite: true,
    slidesToShow: 1,
    lazyLoad: "anticipated",
    focusOnSelect: true,
    autoplay: true,
  };

  useEffect(() => {
    fetch("https://res.cloudinary.com/yneno/image/list/kontorutleie.json")
      .then((res) => res.json())
      .then((data: { resources: Array<ICarouselGallery> }) => {
        const sortedData = [...data.resources].sort(sortImages);
        setGallery(sortedData);
      });
  }, []);
  return (
    <article
      className={`section-container ${styles.wrapper}`}
      style={{ marginBottom: "8rem" }}
    >
      <MetaHeader
        description={
          "Yne tilbyr nå utleie av kontorplasser for små selskaper, Høy standard, 1 min fra stortinget t-bane, takterrasse, møterom, sosial sone."
        }
        url={"https://yne.no/jobb/kontor"}
        title={"Lei kontorplass | Yne"}
        socialMediaDescription={
          "Lei kontorplass hos Yne, et IT selskap med fantastiske kontorer. Sykkelgarasje, garderobe, takterrasse, høy standard, hev/senk pult, skjerm, møterom, sosial sone, fantastisk beliggenhet."
        }
        imageUrl={
          "https://res.cloudinary.com/yneno/image/upload/v1719601501/kontorutleie/12.jpg"
        }
      />

      <h1>Kontorplasser</h1>
      <p>Vi har ledige kontorplasser i totalrenoverte lokaler på Grensen! 🌟</p>
      <p>
        Vi er glade for å annonsere at vi har ledige kontorplasser i våre
        lokaler på Grensen 15. Dette er en fantastisk mulighet for et mindre
        selskap som trenger mellom 6 og 9 kontorplasser.{" "}
      </p>
      <Slider {...settings}>
        {gallery.map((img: ICarouselGallery, index) => (
          <div key={index}>
            <Image
              className={styles["carusel-img"]}
              src={`https://res.cloudinary.com/yneno/image/upload/w_1500,f_auto,q_auto/${img.public_id}.jpg`}
            />
          </div>
        ))}
      </Slider>
      <h2>Hva vi tilbyr</h2>
      <UnorderedList>
        <li>Sentral beliggenhet 1 minutt gange fra Stortinget T-bane stopp</li>
        <li>Hev/senk skrivebord, Håg kontorstol og skjerm for hver plass</li>
        <li>Store vinduer som gir naturlig lys og spektakulær utsikt</li>
        <li>
          Romslig sosialt område med shuffleboard, playstation og nintendo
          switch
        </li>
        <li>Fasiliteter som prosjektor for faglige samlinger</li>
        <li>
          To møterom og fire stillerom tilpasset fysiske møter og
          videokonferanser
        </li>
        <li>Sykkelparkering og garderober med dusj</li>
        <li>
          En imponerende takterrasse med panoramautsikt over Oslo by og fjorden
        </li>
        <li>
          Booking mulighet for kontoret/takterrassen til personlige
          arrangementer som bursdager, spillkvelder, julebord m.m.
        </li>
      </UnorderedList>
      <Image src="https://res.cloudinary.com/yneno/image/upload/v1719601501/kontorutleie/12.jpg" />
      <h3>Nøkkelinfo</h3>
      <p>Areal: 293 m2</p>
      <p>Etasje: 2</p>
      <p>Overtakelse: Etter avtale</p>

      <h3>Fasiliteter</h3>
      <UnorderedList>
        <li>Aircondition</li>
        <li>Heis</li>
        <li>Alarm</li>
        <li>Bredbåndstilknytning</li>
      </UnorderedList>

      <Image src="https://res.cloudinary.com/yneno/image/upload/v1719601498/kontorutleie/1.jpg" />

      <h2>Beliggenhet</h2>
      <p>
        Umiddelbar nærhet til offentlig kommunikasjon, herunder t-bane, trikk og
        buss.
      </p>

      <p>
        Grensen 15 ligger sentralt i krysset Akersgata, grensen, Lille grensen
        med et attraktivt tilbud av shopping, cafe. Og restaurantlivet i
        umiddelbar nærhet. Regjeringskvartalet, Høyesterett, Tinghuset og flere
        departementer ligger rett ved eiendommen og Sentrum P-hus er 300 meter i
        gangavstand.
      </p>

      <p>
        Stortinget T-bane oppgang ligger bokstavelig talt rett utenfor
        inngangsdøren til Grensen 15. Trikkestopp er 150 meter unna, bussen
        stopper ved Regjeringskvartalet og det er fem minutter å gå bort til
        Nationaltheatret for tog, flytog og flybuss. Kommer du med bil er det
        flere parkeringshus i nærheten - blant annet Sentrum P-hus ved
        Tinghuset. For våre syklende leietakere er det egen innendørs
        sykkelparkering i Grensen 15.{" "}
      </p>

      <h2>Litt om oss</h2>
      <p>
        Vi er et dynamisk konsulentselskap i vekst med rundt 10 ansatte, der
        mange av oss ofte jobber hos kunder. Noen av våre verdier inkluderer å
        være inkluderende og lekne, og derfor ønsker vi å dele vår kontorplass
        med noen som verdsetter akkurat dette - et åpent og inkluderende
        arbeidsmiljø. Vi har ingen spesifikke preferanser på hva dere ; vi er
        nysgjerrige på alle og enhver. For oss er dette en flott mulighet til å
        bli kjent med nye mennesker og finne noen vi kan dele arbeidshverdagen
        med 🤗
      </p>

      <p>
        Vi er åpne for både kortsiktige og langsiktige leietakere, og ser frem
        til å bli kjent med dere! 🌟
      </p>
      <p>
        Er du interessert eller kjenner noen som kan være det? Ta kontakt med
        oss i dag!
      </p>
      <Image src="https://res.cloudinary.com/yneno/image/upload/v1704373548/gruppebildet_redigert_versjon_2_gzckc6.jpg" />

      <OfficeContactForm title={`Meld interesse for leie av kontorplasser`} />
    </article>
  );
};

export default OfficePage;
