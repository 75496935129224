import React from "react";
import styles from "./client.module.scss";
import { CLIENTS_LOGO } from "../../helpers/const";

const ClientSection = () => {
  return (
    <section>
      <div className={styles.wrapper}>
        <h2>Kunder</h2>
        <div>
          {CLIENTS_LOGO.map((logo, index) => {
            return (
              <img
                key={index}
                src={logo.url}
                alt={logo.alt}
                width={logo.width}
                height={logo.height}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ClientSection;
