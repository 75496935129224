import React, { ChangeEvent, useRef } from "react";
import { nanoid } from "nanoid";
import styles from "./inputs.module.scss";
import { ILabeledInputProps } from "../../interfaces/input.interfaces"

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, ILabeledInputProps>(
  ({ label, ...props }, ref) => {
    const id = useRef(props.id || nanoid(8));

    function autoResize(event: ChangeEvent<HTMLTextAreaElement>) {
      const el = event.target;
      // Same as in inputs.module.scss
      el.style.height = "1.75rem";
      el.style.height = `${el.scrollHeight}px`;
    }

    return (
      <div className={styles.wrapper}>
        <textarea
          className="input-text"
          {...props}
          id={id.current}
          onChange={autoResize}
          ref={ref}
        />
        <label className="input-label" htmlFor={id.current}>
          {label}
        </label>
      </div>
    );
  }
);

TextAreaInput.displayName = "TextAreaInput";

export default TextAreaInput;
