import React, { FC } from "react";
import Image from "../../../components/image";
export const Backend: FC = () => {
  return (
    <div>
      <p>
        Backend er programvaren som er knyttet til databaser og bakenforliggende
        systemer, der dataene er lagret. Det er avanserte kode hvor de tunge
        kalkuleringer skjer, som brukeren ikke ser, men som er svært viktig for
        brukeropplevelsen. Vi i Yne finner de mest moderne løsningene for våre
        kunder og hjelper gjerne til med å velge rett plattform. Våre erfarne
        utviklere er vant til å gjøre komplekse problemstillinger om til smidige
        løsninger. Dersom dere er usikker på hvilken teknologi dere har behov
        for, veileder vi dere mer enn gjerne til å velge rett teknologi for
        dere. Vi kan levere hele løsninger bestående av design, frontend og
        backend. Ta kontakt med oss for løsningsforslag og pris!
      </p>
      <h2>Prosjektomfang Axo Finans</h2>
      <p>
        Yne har bistått Axo Finans i utskiftingen av silobaserte løsninger og
        implementeringen av en felles generell løsning som skal virke sømløst
        for alle produkter i Axos markeder. Denne er kalt One Platform og skal
        kunne hente inn søknadsdata fra brukere i forskjellige markeder med
        forskjellige markedsbehov. Plattformen er bygget ved hjelp av moderne
        teknologi som event-baserte serverless functions skrevet i GO, som
        kjører på AWS Lambda. På frontend brukes React, NextJs og TypeScript.
      </p>
      <Image
        src="https://res.cloudinary.com/yneno/image/upload/v1717862534/cases/axo-brage-david_c0vsul.jpg"
        alt="Brage og David var blant konsulentene som bisto Axo Finans"
      />
    </div>
  );
};
