import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { Router } from "./router";
import { NO_FOOTER_PAGES } from "./helpers/const";

function App() {
  const { pathname } = useLocation();

  return (
    <div
      className={`App app-wrapper ${
        pathname.substr(1).includes("/")
          ? "details"
          : pathname.substr(1)
          ? pathname.substr(1)
          : "home"
      }`}
    >
      <Header />
      <main>
        <Router />
      </main>
      {!NO_FOOTER_PAGES.includes(pathname) && <Footer />}
    </div>
  );
}

export default App;
