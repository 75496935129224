import React, { useEffect, useState } from "react";
import { InnerLink, ViewLink } from "../../components/links/";
import ClientSection from "../../components/client-section";
import InstagramFeed from "../../components/instagram-feed";
import MetaHeader from "../../components/metaheader";
import { CLOUDINARY_IMG, SERVICE_INFO } from "../../helpers/const";
import { ServiceItem } from "../../components/service-item";
// import { ToggleVideoMute } from "../../components/buttons";
// import YoutubeBackground from "react-youtube-background";
// import iconArrow from "../../assets/icons/icon_arrow.svg";
import inst_logo from "../../assets/icons/inst-logo.png";
import sanityClient from "../../helpers/client";
import styles from "./home.module.scss";
import { specialLogoVariable, urlFor } from "../../helpers/functions";
import SlickCarousel from "../../components/carousel";
import BlogFeed from "../../components/blog-feed";
import { ProjectList } from "../projects/components/project-list";
import SwipeService from "./SwipeService";
import Image from "../../components/image";
import { Link } from "react-router-dom";
import HomeIntroText from "./HomeIntroText";
import ProjectQuote from "../projects/components/ProjectQuote";
import KundeReferanse from "./KundeReferanse";
import YoutubeEmbed from "../../components/youtube-embed";

const Home = () => {
  const [allPostsData, setAllPosts] = useState<any>();
  // const [player, setPlayer] = useState<any>(null);
  // const [muteToggle, setMuteToggle] = useState<boolean>(false);
  // const secondScreenRef = useRef<any>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "cases"]{
            title,
            description,
            keyInfo,
            _id,
            slug,
            link,
            categories[]->,
            mainImage {
              caption,
              asset->{
              _id,
              url
              }
            }
          }`
      )
      .then((data) => {
        setAllPosts(data);
      })
      .catch(console.error);
  }, []);

  // const onReady = (event: any) => {
  //   setPlayer(event.target);
  // };

  // const onToggleMute = () => {
  //   setMuteToggle((muteToggle) => (muteToggle = !muteToggle));
  //   muteToggle ? player.mute() : player.unMute();
  // };

  // function scrollToSecondScreen() {
  //   secondScreenRef.current.scrollIntoView({ behavior: "smooth" });
  // }

  // const base = "Konsulentselskapet <wbr> som er eksperter på";
  const specialOcasion = specialLogoVariable();
  const yneIllustrationImage =
    specialOcasion === "christmas"
      ? CLOUDINARY_IMG.HOME.yne_illustration_christmas
      : specialOcasion === "newyear"
      ? CLOUDINARY_IMG.HOME.yne_illustration_newyear
      : CLOUDINARY_IMG.HOME.yne_illustration;
  return (
    <>
      <MetaHeader
        description="Velkommen til Yne - Ditt ultimate konsulentselskap for systemutvikling! Vårt lekne og innovative team behersker fullstack-magi, fra Java og Kotlin til JavaScript og .NET. Bli med oss på reisen mot innovasjon, engasjement og inkludering, der kreativitet møter koding for en grenseløs digital opplevelse. Yne - Av utviklere, for utviklere."
        url="https://yne.no/"
        title="Hjem | Yne | En profesjonell totalleverandør innenfor digitale
      prosjekter."
        socialMediaDescription="Yne - Av utviklere, for utviklere!"
        imageUrl={CLOUDINARY_IMG.HOME.team_party}
      />
      {/* 
      <div className={styles.videoContainer}>
        <ToggleVideoMute onClick={onToggleMute} iconType={muteToggle} />
        <button
          className={styles.scroll_next_screen}
          onClick={scrollToSecondScreen}
        >
          <img src={iconArrow} alt="" />
        </button>
        <div className={styles.overlay} />
        <div className={styles.youtube_wrapper}>
          <YoutubeBackground videoId={"4Ksi5z2e8JI"} onReady={onReady} />
        </div>
      </div>
      <span ref={secondScreenRef}></span> */}

      <div className={`section-container ${styles.intro}`}>
        <div className={styles.flex_col}>
          <HomeIntroText />
        </div>

        <div className={styles.yne_illustration_wrapper}>
          <div className={styles.yne_illustration}>
            <div className={styles.image_wrapper}>
              <img
                src={yneIllustrationImage}
                alt="yne figur som samarbeider "
              />
            </div>
          </div>
          <div className={styles.contact_button}>
            {/* <InnerLink link="/kontakt-oss" name="Kontakt oss" /> */}
          </div>
        </div>
      </div>

      <div className={`section-container`}>
        <div className={styles.info}>
          <div></div>
        </div>
        <KundeReferanse
          heading="Hør fra våre kunder"
          cardTitle="Verdi levert"
          cardContent="Det vi er mest fornøyd med i samarbeidet med Yne er at de snur
        seg rundt kjapt, løser oppgavene veldig bra, og leverer som avtalt
        med et glimt i øyet."
          companyRepresentative="Penelope Lilan - "
          company="Axo Group"
          separator
          footer
        >
          <YoutubeEmbed embedId={"EiaLQ27G9OY"} />
        </KundeReferanse>
      </div>

      <div className={`section-container`}>
        <ClientSection />
      </div>

      <div className={`section-container`}>
        <div className={styles.info}>
          <div></div>
        </div>
        <KundeReferanse
          cardTitle="Engasjerte og innovative konsulenter"
          cardContent="Yne kombinerer profesjonalitet med en leken tilnærming til problemstillinger. Vi ser på dine utfordringer som innovative arenaer, hvor vi utforsker og eksperimenterer for å skape løsninger etter skreddersøm."
        >
          <div className={styles.imageWrapper}>
            <Image
              src="https://res.cloudinary.com/yneno/image/upload/v1699261953/Bildekarusellen/Klappebildet_til_nettside_ofqe1k.jpg"
              alt="people at conferance clapping"
            />
          </div>
        </KundeReferanse>
      </div>

      <div className={`${styles.service}`}>
        <div>
          <div className={styles.service__header}>
            <h1>Våre tjenester</h1>
          </div>

          <div className={styles.service__body}>
            {SERVICE_INFO.map(({ icon, title, info, link }, index) => (
              <ServiceItem
                link={link}
                index={index}
                key={title}
                title={title}
                icon={icon}
                info={info}
              />
            ))}
          </div>
          <SwipeService />
        </div>
      </div>

      <div className={styles.info_center}>
        <div></div>
      </div>

      <ProjectQuote route={"sector-alarm"} />
      <div className={styles["cases_wrapper"]}>
        <h3>Noen av våre prosjekter</h3>
        <ProjectList columns={3} limit={3} sortFn={() => 0.5 - Math.random()} />
        <div className={styles["cases_call_to_action"]}>
          <InnerLink
            link="/prosjekter"
            name="Flere prosjekter"
            withArrow
          ></InnerLink>
        </div>
      </div>
      <div className={styles.info_center}>
        <div></div>
      </div>
      <div className={`section-container`}>
        <div className={styles.holding_hands_illustration_wrapper}>
          <div className={styles.holding_hands_illustration}>
            <Link to="/om-oss#folk">
              <img
                src={CLOUDINARY_IMG.HOME.holding_hands_illustration}
                alt=""
              />
            </Link>
          </div>
          <div className={styles.see_consultants}>
            <InnerLink
              link="/om-oss#folk"
              name="Se våre konsulenter her"
            ></InnerLink>
          </div>
        </div>
      </div>

      <div className={`${styles.blog_feed}`}>
        <h2 className={styles.header}>Høydepunkt fra bloggen</h2>
        <BlogFeed />
      </div>

      <div className={styles.aboutus}>
        <div className={styles.carusel}>
          <SlickCarousel />
        </div>
        <div className={styles.aboutus__info}>
          <div className={`colored-background`}>
            <p>
              Jobben er en stor del av livet vårt, og derfor ønsker vi at jobben
              skal være minst like gøy som alt annet her i livet. For oss skal{" "}
              <strong>kollegene</strong> være <strong>venner</strong>, og det
              skal være <strong>gøy å komme på jobb!</strong>
            </p>
          </div>
        </div>
        <div className={styles.aboutus__link}>
          <div
            className={`colored-background ${styles.aboutus__link__wrapper}`}
          >
            <InnerLink link="/jobb" name="Jobbe i yne?" withArrow />
          </div>
        </div>
        <div className={`colored-background`}></div>
      </div>

      <div className={styles.cases}>
        <h2>Noen av våre referanser</h2>
        <div className={styles.cases_wrapper}>
          {allPostsData &&
            allPostsData.map((post: any) => (
              <div key={post?._id} className={styles.job_wrapper}>
                <div className={styles.job_info}>
                  <div className={styles.cases_image_wrapper}>
                    <img
                      src={urlFor(post.mainImage).width(1024).url()}
                      alt={post.mainImage.caption}
                    />
                  </div>
                  <p className="paragraph-2">{post.keyInfo}</p>
                  <h3>{post.title}</h3>
                  <p>{post.description}</p>
                  {post.link && (
                    <div className={styles.details}>
                      <ViewLink link={post.link} name="Besøk siden" withArrow />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.inst_wrapper}>
        <a
          href="https://www.instagram.com/vi_er_yne/"
          target="_blanck"
          className={`inner-link ${styles.inst_cta}`}
        >
          <img src={inst_logo} alt="" />
          <p>vi_er_yne</p>
        </a>

        <InstagramFeed />
      </div>
    </>
  );
};

export default Home;
