import React from "react";
import iconArrow from "../../assets/icons/icon_arrow.svg";
import styles from "./buttons.module.scss";

const PageScrollButton = () => {
  return (
    <button className={styles.scroll} onClick={() => window.scrollTo(0, 0)}>
      <p>Topp</p>
      <img src={iconArrow} alt="" />
    </button>
  );
};

export default PageScrollButton;
