import { Document, Page, pdfjs } from "react-pdf";
import React, { useEffect, useState } from "react";

import { useWindowWidth } from "../../hooks/useWindowWidth";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OldPersonnelHandbook = () => {
  const width = useWindowWidth();
  const fileurl = `https://res.cloudinary.com/yneno/image/upload/v${Math.floor(
    new Date().getTime() / 1000
  )}/assets/Personalhaandbok.pdf`;
  const [file] = useState(fileurl);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  useEffect(() => {
    window.location.href = fileurl;
  }, [fileurl]);

  return (
    <div>
      {
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_el, index) => (
            <>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={Math.min(width * 0.9)}
              />
            </>
          ))}
        </Document>
      }
    </div>
  );
};

export default OldPersonnelHandbook;