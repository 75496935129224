import React, { ReactNode } from "react";
import defaultStyles from "./default-list.module.scss";
import styles from "./ordered-list.module.scss";

type Props = React.DetailedHTMLProps<
  React.OlHTMLAttributes<HTMLOListElement>,
  HTMLOListElement
>;

export function OrderedList({ className, children, ...props }: Props) {
  return (
    <ol
      className={`${defaultStyles.root} ${styles.root} ${className ?? ""}`}
      {...props}
    >
      {React.Children.map(children, enrichListElement)}
    </ol>
  );
}

function enrichListElement(child: ReactNode, index: number) {
  if (!React.isValidElement<HTMLLIElement & Record<string, unknown>>(child)) {
    return child;
  }

  const prefix = index < 10 ? "0" : "";
  const listNumber = index + 1;

  return React.cloneElement(child, {
    "data-before": `${prefix}${listNumber}`,
    className: "ass",
  });
}
