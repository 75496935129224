import { useQuery } from "@tanstack/react-query";
import sanityClient from "../../helpers/client";
import { Project, ProjectDetail } from "./projects.types";

function fetchProjects() {
  return sanityClient.fetch(
    `*[_type == "cases"]{
        _id,
        title,
        description,
        keyInfo,
        slug,
        mainImage {
          caption,
          asset->{
          _id,
          url
          }
        },
        shouldShowDetails
      }`
  );
}

export function useProjects() {
  return useQuery<Project[]>(["projects"], fetchProjects);
}

function fetchProject(slug?: string) {
  return sanityClient.fetch(
    `*[_type == "cases" && slug.current == $slug ]{
          _id,
          title,
          description,
          keyInfo,
          video,
          slug,
          mainImage {
            caption,
            asset->{
            _id,
            url
            }
          },
          details,
          sections[] {
              titleLeft,
              textLeft,
              titleRight,
              textRight,
              image {
                  caption,
                  asset->{
                      url
                  }
              }
          },
          quote
        }`,
    {
      slug,
    }
  );
}

export function useProject(slug?: string) {
  return useQuery<ProjectDetail[]>(
    ["project", slug],
    () => fetchProject(slug),
    { enabled: !!slug }
  );
}
