import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import sanityClient from "../../helpers/client";
import { useParams } from "react-router-dom";
import styles from "./job-info.module.scss";
import ContactForm from "../../components/contact-form";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const BlockContent = require("@sanity/block-content-to-react");

export default function JobInfo() {
  const [postData, setPostData] = useState<any>(undefined);

  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          description,
          slug,
          publishedAt,
          categories[]->,
          mainImage{
            caption,
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return null;

  return (
    <>
      <Helmet>
        <title>Jobs | Yne</title>
      </Helmet>
      <div className={`section-container ${styles.job_info}`}>
        <h1>{postData.title}</h1>
        {postData.mainImage && (
            <img src={postData.mainImage.asset.url} alt="" className={styles.mainImage} />
          )}
          <BlockContent
            blocks={postData.body}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
          />

          <ContactForm title={`Meld interesse som ${postData.title}`} />
       
      </div>
    </>
  );
}
