import React from "react";
import styles from "./cards.module.scss";
import { IContactMember } from "../../interfaces/card.interfaces";

const ContactMember = ({ ...item }: IContactMember) => {
  return (
    <div className={styles.member}>
      <picture>
        <source srcSet={item.image_webp} type="image/webp" />
        <img src={item.image} alt={item.name} />
      </picture>
      <div>
        <h3>{item.name}</h3>
        <br />
        <p>
          <strong>{item.title}</strong>
        </p>
        <p>{item.phone}</p>
        <p>
          <a href={`mailto:${item.email}`}>{item.email}</a>
        </p>
      </div>
    </div>
  );
};

export default ContactMember;
