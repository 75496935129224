import React, { useState } from "react";
import styles from "./contact-form.module.scss";
import { BaseInput, TextAreaInput } from "../inputs";
import { FormButton } from "../buttons";
import { NavLink } from "react-router-dom";
import crose from "../../assets/icons/crose.svg";

interface ContactFormData extends HTMLFormElement {
  email: HTMLInputElement;
  melding: HTMLInputElement;
  seats: HTMLInputElement;
}

interface ContactFormEvent extends React.FormEvent<ContactFormData> {
  target: ContactFormData;
}

const OfficeContactForm = ({ title }: { title: string }) => {
  const [msg, setMsg] = useState("");
  const handleClick = () => setMsg("");

  const handleSubmit = (e: ContactFormEvent) => {
    e.preventDefault();

    if (!e.target) {
      return;
    }

    const form = e.target;

    const formData = {
      title: title,
      email: form.email.value,
      melding: form.melding.value,
      seats: form.seats.value,
    };

    function encode(data: { [key: string]: string }): string {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "jobb", ...formData }),
    })
      .then(() => setMsg("Takk for at du tok kontakt med oss!"))
      .catch((error) => alert(error));
  };

  return (
    <div className={styles.form_wrapper}>
      <h3>{title}</h3>

      <form onSubmit={handleSubmit} name="jobb" method="post">
        <BaseInput
          label="E-post *"
          type="email"
          name="email"
          placeholder="Epost"
          required
        />
        <TextAreaInput
          label="Melding *"
          name="melding"
          placeholder="Melding"
          required
        />
        <BaseInput
          label="Antall plasser *"
          type="number"
          name="seats"
          placeholder="Antall plasser link"
          required
        />
        <FormButton />
      </form>

      {msg && (
        <div className={styles.success_wrapper}>
          <div className={styles.success_message}>
            <NavLink to="/" onClick={handleClick}>
              <img src={crose} alt="close" />
            </NavLink>
            <h1>{msg}</h1>
            <p>
              Vi setter pris på at du kontaktet oss. En av våre kolleger vil
              straks ta kontakt med deg!
            </p>

            <p>Ha en fortsatt fin dag!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfficeContactForm;
