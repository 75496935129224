import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../helpers/client";
import styles from "./blog-feed.module.scss";
import { urlFor } from "../../helpers/functions";
import { InnerLink } from "../links";
import {
  Post,
  Props,
  HighlightedPostProps,
  SecondaryPostsProps,
} from "../../interfaces/blog.interfaces";
import Image from "../../components/image";

const BlogFeed = ({ highlightedSlug }: Props) => {
  const [allPostsData, setAllPosts] = useState<Post[]>([]);
  const [highlightedPost, setHighlightedPost] = useState<Post>();

  const sortPosts = (a: Post, b: Post) => {
    if (a.publishedAt === undefined) return -1;
    if (b.publishedAt === undefined) return 1;
    return (
      new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    );
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
            title,
            slug,
            publishedAt,
            categories[]->,
            mainImage{
              caption,
              asset->{
              _id,
              url
              }
            }
          }`
      )
      .then((data) => setAllPosts(data.sort(sortPosts).slice(0, 3)))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (highlightedSlug) {
      sanityClient
        .fetch(
          `*[slug.current == $highlightedSlug]{
            title,
            slug,
            mainImage{
              asset->{
                _id,
                url
               }
             },
           body,
          "name": author->name,
          "authorImage": author->image
         }`,
          { highlightedSlug }
        )
        .then((data) => setHighlightedPost(data[0]))
        .catch(console.error);
    } else {
      setHighlightedPost(allPostsData[0]);
    }
  }, [highlightedSlug, allPostsData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left_column}>
        {highlightedPost && <HighlightedPost post={highlightedPost} />}
      </div>
      <div className={styles.right_column}>
        <div>
          <SecondaryPosts posts={allPostsData.slice(-2)} />
        </div>
        <div>
          <p className={styles.description}>
            ... Disse og flere spennende innlegg venter i bloggen vår
          </p>
          <div className={styles.link}>
            <InnerLink link="/blogg" name="Gå til bloggen" withArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

const HighlightedPost = ({ post }: HighlightedPostProps) => {
  return (
    <Link
      to={"/blogg/" + post.slug.current}
      key={post.slug.current}
      className={styles.main_post}
    >
      <Image
        src={urlFor(post.mainImage).width(1000).url()}
        alt={post.mainImage.caption || ""}
        className={styles.main_image}
      />
      <span className={styles.main_post_info}>
        <p className={styles.main_post_category}>
          {post.categories && post.categories[0].title}
        </p>
        <h3 className={styles.main_post_title}>{post.title}</h3>
      </span>
    </Link>
  );
};

const SecondaryPosts = ({ posts }: SecondaryPostsProps) => {
  return (
    <>
      {posts.map((post: Post) => (
        <Link
          to={"/blogg/" + post.slug.current}
          key={post.slug.current}
          className={styles.secondary_post}
        >
          <Image
            src={urlFor(post.mainImage).width(1000).url()}
            alt={post.mainImage.caption || ""}
            className={styles.secondary_image}
          />
          <span className={styles.secondary_post_info}>
            <h3 className={styles.secondary_post_title}>{post.title}</h3>
          </span>
        </Link>
      ))}
    </>
  );
};

export default BlogFeed;
