import React from "react";
import styles from "./image.module.scss";

interface ImageProps {
    className?: string;
    src: string;
    alt?: string;
  }

const Image = ({ className, src, alt }: ImageProps) => (

  <img
    className={`${styles.img} ${className}`}
    src={src}
    alt={alt}
  />

)

export default Image