import React, { ReactNode } from "react";
import CodeBlock from "../components/code-block";
import { urlFor } from "./functions";
import sanityClient from "./client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SanityBlockContent from "@sanity/block-content-to-react";
import UnorderedList from "../components/list/unordered-list";
import { OrderedList } from "../components/list";

const serializers = {
  types: {
    image: ({ node }: any) => {
      if (!node || !node.asset || !node.asset._ref) {
        return null;
      }
      return (
        <>
          <figure>
            <img src={urlFor(node.asset)} alt={node.caption} style={{borderRadius: "var(--img-border-radius)"}} />
            <figcaption>{node.caption}</figcaption>
          </figure>
        </>
      );
    },
    code: ({ node }: any) => {
      if (!node || !node.language || !node.code) {
        console.warn("Missing required properties to show code block");
        return null;
      }

      return <CodeBlock language={node.language} code={node.code} />;
    },
  },
  list: ({ type, ...props }: { type: "bullet" | "number" }) => {
    if (type === "number") {
      return <OrderedList {...props} />;
    }

    return <UnorderedList {...props} />;
  },
  listItem: (props: Record<string, unknown>) => (
    <li data-before={props["data-before"]}>{props.children as ReactNode}</li>
  ),
};

interface BlockContentProps {
  blocks: unknown;
}

export function BlockContent({ blocks }: BlockContentProps) {
  return (
    <SanityBlockContent
      blocks={blocks}
      {...sanityClient.config()}
      serializers={serializers}
    />
  );
}
