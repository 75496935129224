import React, { useEffect, useState } from "react";
import "./header.scss";
import { NavLink, Link } from "react-router-dom";
import {
  MAGIC_NUMBER_60,
  MENU_ITEMS,
  MEDIUM_BREAKPOINT,
} from "../../helpers/const";
import { MenuButton } from "../hamburger-menu";
import SideMenu from "../hamburger-menu/side-menu";
import { specialLogoVariable } from "../../helpers/functions";
import PrideFlag from "../prideflag";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (window.screen.width < MEDIUM_BREAKPOINT) {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > MAGIC_NUMBER_60);
      });
    }
  }, []);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  const specialOcasion = specialLogoVariable();

  return (
    <header
      className={`${"header"} ${toggle ? "with-background" : ""} ${
        scroll ? "with-shadow" : ""
      } ${specialOcasion}`}
    >
      <Link to="/" className="header-link">
        {" "}
      </Link>

      {specialOcasion === "pride" && <PrideFlag />}
      <nav>
        <MenuButton handleClick={toggleMenu} toggle={toggle} />
        <SideMenu handleClick={toggleMenu} toggle={toggle} />
        <ul className="header__menu">
          {MENU_ITEMS.map(({ link, name }) => (
            <li key={name}>
              <NavLink to={link} className="button-link-text header-link">
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
