import React from "react";
import { Helmet } from "react-helmet-async";

interface MetaHeaderProps {
  description: string;
  url: string;
  title: string;
  socialMediaDescription: string;
  imageUrl: string;
}
const MetaHeader = ({
  description,
  url,
  title,
  socialMediaDescription,
  imageUrl,
}: MetaHeaderProps) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={socialMediaDescription} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:image:secure_url" content={imageUrl} />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="640" />
    <meta property="og:image:height" content="442" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={url} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={socialMediaDescription} />
    <meta property="twitter:image" content={imageUrl} />

    <meta property="og:site_name" content={title} />
    <meta name="twitter:image:alt" content={title} />
  </Helmet>
);

export default MetaHeader;
