import React from "react";
import { BlockContent } from "../../helpers/sanity";
import { Block } from "../../interfaces/handbook-blocks.interfaces";
import UnderlinedTitle from "../title";
import styles from './handbook-blocks.module.scss'
import { urlFor } from "../../helpers/functions";
import { BACKGROUND_IMAGE_VARIANTS, BACKGROUND_LEFT_VARIANTS, BACKGROUND_RIGHT_VARIANTS } from "../../helpers/const";

export const TextWithImageBlock = (data : Block) => {
  const { title, bodyLeft, bodyRight, image, backgroundPosition } = data;

  return (
    <div className={styles.textWithImageBlock} id={title}>
      {bodyLeft && <div className={BACKGROUND_LEFT_VARIANTS.includes(backgroundPosition || '')  ? styles.coloredBackground : ''}>
        { title && <UnderlinedTitle title={title} /> }
        <BlockContent blocks={bodyLeft} />
      </div>}
      <div>
        <div className={`${styles.imageWrapper} ${BACKGROUND_IMAGE_VARIANTS.includes(backgroundPosition || '') ? styles.coloredBackground : ''}`}>
          {image &&
            <img src={urlFor(image.asset).auto('format').fit('max').width(620)} alt={'data.caption'} loading="lazy"/>
          }
        </div>
        {bodyRight && <div className={BACKGROUND_RIGHT_VARIANTS.includes(backgroundPosition || '') ? styles.coloredBackground : ''}>
          { title && bodyLeft === 'undefiend' && <UnderlinedTitle title={title} /> }
          <BlockContent blocks={bodyRight} />
        </div>}
      </div>
    </div>
  )
} 
