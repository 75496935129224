import React from "react";
import styles from "./not-found-page.module.scss";
import not_found from "../../assets/images/404.svg";

const NotFoundPage = () => (
  <div className={`section-container ${styles.wrapper}`}>
    <img src={not_found} alt="not found" />
    <h3>Siden finnes ikke</h3>
    <p>
      Her har det skjedd en feil. Send oss gjerne en mail på <a href="mailto:kontakt@yne.no">kontakt@yne.no</a> med linken og en beskrivelse på hvordan du havnet her. Ha en fin dag videre!
    </p>
  </div>
);

export default NotFoundPage;
