import React from "react";
import styles from "./links.module.scss";
import { SOCIAL_LINKS } from "../../helpers/const";

const SocialLinks = () => (
  <ul className={`${styles.social} social-link-bar`}>
    {SOCIAL_LINKS.map(({ link, icon, name }) => (
      <li key={name}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={icon} alt={name} />
        </a>
      </li>
    ))}
  </ul>
);

export default SocialLinks;
