import React, { useEffect, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "./carousel.scss";

export interface ICarouselGallery {
  created_at: string;
  format: string;
  height: number;
  public_id: string;
  type: string;
  version: number;
  width: number;
}

const shuffleArray = (array: Array<ICarouselGallery>) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SlickCarousel = () => {
  const [gallery, setGallery] = useState<Array<ICarouselGallery>>([]);

  const settings: Settings = {
    infinite: true,
    slidesToShow: 1,
    lazyLoad: "anticipated",
    focusOnSelect: true,
    autoplay: true,
  };

  useEffect(() => {
    fetch("https://res.cloudinary.com/yneno/image/list/bildekarussel.json")
      .then((res) => res.json())
      .then((data) => {
        setGallery(shuffleArray(data.resources));
      });
  }, []);

  return (
    <div className="wrapdiv">
      <div className="slider_wrapper">
        <Slider {...settings}>
          {gallery.map((img: ICarouselGallery, index) => (
            <div key={index}>
              <img
                src={`https://res.cloudinary.com/yneno/image/upload/w_1500,f_auto,q_auto/${img.public_id}.jpg`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SlickCarousel;
