import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Pin from "../../assets/pin.svg";

const containerStyle = {
  width: "100%",
  height: "100%",
  filter: "saturate(0.1) contrast(80%) brightness(110%)",
};

const GOOGLE_MAPS_TOKEN = process.env.REACT_APP_GOOGLE_MAPS_TOKEN;

function SimpleMap({ lat, lng }: { lat: number; lng: number }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_TOKEN as string,
  });

  return isLoaded ? (
    <div style={{ height: "100vh", maxHeight: "531px", width: "100%" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: lat, lng: lng }}
        zoom={15}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <Marker icon={Pin} position={{ lat: lat, lng: lng }} />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(SimpleMap);
