import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../helpers/client";
import styles from "./blog.module.scss";
import { urlFor } from "../../helpers/functions";
import { Post } from "../../interfaces/blog.interfaces";
import Image from "../../components/image";
import MetaHeader from "../../components/metaheader";

const Blog = () => {
  const [allPostsData, setAllPosts] = useState<Post[]>([]);
  const [metaImageUrl, setMetaImageUrl] = useState("");
  const effectRan = useRef(false);

  const sortPosts = (a: Post, b: Post) => {
    if (a.publishedAt === undefined) return -1;
    if (b.publishedAt === undefined) return 1;
    return (
      new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    );
  };

  const findMetaImage = (posts: Post[]): string => {
    for (const post of posts) {
      if (post?.mainImage?.asset?.url != undefined) {
        return post.mainImage.asset.url;
      }
    }
    return "";
  };
  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    sanityClient
      .fetch(
        `*[_type == "post"]{
            title,
            description,
            slug,
            publishedAt,
            categories[]->,
            mainImage{
              caption,
              asset->{
              _id,
              url
              }
            }
          }`
      )
      .then((data) => setAllPosts(data.sort(sortPosts)))
      .catch(console.error);
  }, []);

  useEffect(() => {
    setMetaImageUrl(findMetaImage(allPostsData));
  }, [allPostsData]);

  return (
    <>
      <MetaHeader
        title="Blogg | Yne"
        description="Finn ut hva som rører seg i Yne om dagen. Les nyttige blogg poster fra Yne her."
        imageUrl={metaImageUrl}
        url="https://yne.no/blogg"
        socialMediaDescription="Finn ut hva som rører seg i Yne om dagen. Les nyttige blogg poster fra Yne her."
      />
      <div className={styles.wrapper}>
        <h1>Yne blogg</h1>
        <div className={styles.posts}>
          {allPostsData &&
            allPostsData.map((post: Post) =>
              post?.slug?.current ? (
                <Link
                  to={"/blogg/" + post.slug.current}
                  key={post.slug.current}
                  className={styles.post}
                >
                  {post.mainImage && (
                    <Image
                      src={urlFor(post.mainImage).width(1024).url()}
                      alt={post.mainImage.caption || ""}
                    />
                  )}
                  <div className={styles.img_overlay}></div>
                  <span className={styles.post_info}>
                    <p className="category-label">
                      {post.categories && post.categories[0].title}
                    </p>
                    <h3>{post.title}</h3>
                    <p className={styles.description}>{post.description}</p>
                  </span>
                </Link>
              ) : null
            )}
        </div>
      </div>
    </>
  );
};

export default Blog;
